import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { storageService } from 'services/storage.service';
import { RootState } from 'stores/store';

import AuthData from 'types/User';
interface UserState {
  userInfo: AuthData | null;
}

const initialState: UserState = {
  userInfo:
    storageService.getSessionStorageObject('users') ||
    storageService.getLocalStorageObject('users') ||
    null,
};

const userSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setUserInfo: (state, action: PayloadAction<AuthData>) => {
      state.userInfo = action.payload;
    },
    modifyUserInfo: (state, action: PayloadAction<Partial<AuthData>>) => {
      if (state.userInfo) {
        state.userInfo = {
          jwt: state.userInfo.jwt,
          user: { ...state.userInfo.user, ...action.payload },
        };
      }
      storageService.setSessionStorageObject('users', state.userInfo);
    },
    clearUserInfo: (state) => {
      state.userInfo = null;
      storageService.clearLocalStorage();
      storageService.clearSessionStorage();
    },
  },
});

export const { setUserInfo, modifyUserInfo, clearUserInfo } = userSlice.actions;
export default userSlice.reducer;

export const selectUserInfo = (state: RootState) => state.users.userInfo;
