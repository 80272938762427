import axios from 'axios';
const API_URL = process.env.REACT_APP_REST_API_LOCATION;

const departementService = {
  list: async (locale?: string) => {
    try {
      const response = await axios.get(
        `${API_URL}/departments?locale=${locale}&fields[0]=departementId&fields[1]=name`
      );
      return response.data;
    } catch (error) {
      console.error('Error during login request:', error);
      throw error;
    }
  },
};

export default departementService;
