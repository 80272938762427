import LoaderGameiro from 'components/LoaderGameiro/Loader'
import ProductItem from 'pages/PurchasingCenter/ProductItem'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { fetchSupports, selectSupports } from 'stores/slices/support-slice'
import './style.scss'

const ProductPage: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { supports, status } = useSelector(selectSupports)
  const { lang } = useParams()

  useEffect(() => {
    dispatch(fetchSupports(lang || 'fr') as any)
  }, [dispatch, lang])

  const showLoading = () => {
    return status === 'loading' && supports.length === 0
  }

  return (
    <>
      <main className="main-container main-container--w-bg main-container--w-bg-no">
        <div className="container">
          <div className="product-page">
            <div className="row block--mb-45">
              <div className="col-12 col-sm-6 ">
                <div className="product-page__content">
                  <h1 className="title"> {t('HEADER.Support')}</h1>
                  <p className="subtitle">{t('SUPPORT.SUB_TITLE')}</p>
                </div>
              </div>
              <div className="col-12 col-sm-6">
                <div className="product-page__image">
                  <img src="/support-digitaux.png" alt="Header Imagery" />
                </div>
              </div>
            </div>
          </div>

          <div className="product-item__row">
            {showLoading() ? (
              <LoaderGameiro />
            ) : (
              <>
                {' '}
                {supports &&
                  supports?.length > 0 &&
                  supports.map((support) => (
                    <ProductItem key={support.id} support={support} />
                  ))}{' '}
              </>
            )}
          </div>
        </div>
      </main>
    </>
  )
}

export default ProductPage
