/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
import LoaderGameiro from 'components/LoaderGameiro/Loader'
import ModaLogout from 'components/Modal/Logout/Logout'
import { FormErrors, FormState } from 'components/RegisterForm/RegisterForm'
import { parsePhoneNumberFromString } from 'libphonenumber-js'
import React, { useCallback, useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import authService from 'services/auth.service'
import { modifyUserInfo, selectUserInfo } from 'stores/slices/user-slice'
import './style.scss'

export interface Coptation {
  id: number
  name: string
  email: string
  photo: string
  firstname: string
}

// TODO: USE COMPONENT REGISTER
const Profil: React.FC = () => {
  const { lang } = useParams()
  const { t } = useTranslation()
  const [coptaUser, setCoptation] = useState<Coptation[]>([])
  const [childUser, setChildUser] = useState<any[]>([])
  const [loading, setLoading] = useState(false)
  const [loadingCopt, setLoadingCopt] = useState(false)
  const [loadingChild, setLoadingChild] = useState(false)
  const [errors, setErrors] = useState<FormErrors>({})
  const userInfo = useSelector(selectUserInfo)
  const [isClicked, setIsClicked] = useState(false)
  const [modalShow, setModalShow] = useState(false)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const initialFormState = {
    name: userInfo?.user?.name || '',
    firstName: userInfo?.user?.firstname || '',
    email: userInfo?.user?.email || '',
    disctrictName: userInfo?.user.department?.name || '',
    deleteFile: 0,
    country:
      userInfo?.user?.lang.toLocaleUpperCase() ||
      lang?.toLocaleUpperCase() ||
      'FR',
    district: '',
    phone: userInfo?.user?.phone_number || '',
    SIRET: null,
    PHOTO: null,
    identityProof: null,
    insuranceCertificate: null,
    password: '',
    photoViewMode: true,
    siretViewMode: userInfo?.user.siret_file?.id ? true : false,
    identityViewMode: userInfo?.user.IdFile?.id ? true : false,
    insuranceViewMode: userInfo?.user.insurance_file?.id ? true : false,
  }
  const [showPassword, setShowPassword] = useState(false)
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
    const meInfo = async () => {
      try {
        const response = await authService.getMeInfo()
        dispatch(modifyUserInfo(response))
      } catch (error) {
        console.error(
          "Erreur lors de la récupération des données de l'API 1",
          error,
        )
      }
    }

    const checkCoptation = async () => {
      try {
        setLoadingCopt(true)
        const response = await authService.getCoptation(userInfo?.user?.id)
        if (response?.data) {
          setCoptation(response?.data?.reverse())
          setLoadingCopt(false)
        } else {
          setLoadingCopt(false)
        }
      } catch (error) {
        setLoadingCopt(false)
        console.error(
          "Erreur lors de la récupération des données de l'API 2",
          error,
        )
      }
    }

    const checkChild = async () => {
      try {
        setLoadingChild(true)
        const response = await authService.child(
          userInfo?.user?.id.toString() || '',
        )
        if (response?.data) {
          setChildUser(response?.data)
          setLoadingChild(false)
        } else {
          setLoadingChild(false)
        }
      } catch (error) {
        setLoadingChild(false)
        console.error(
          "Erreur lors de la récupération des données de l'API 3",
          error,
        )
      }
    }
    checkChild()
    meInfo()
    checkCoptation()
  }, [])

  const showLoading = () => {
    if (loadingCopt || loadingChild) {
      return true
    }
  }

  const [form, setForm] = useState<FormState>(initialFormState)

  const truncateText = (text: string) => {
    return text.length > 40 ? text.substring(0, 40) + '...' : text
  }

  const validateField = (name: string, value: string | File | null): string => {
    switch (name) {
      case 'name':
        if (!value) return t('PROFILS.ERROR.LASTNAME')
        break

      case 'firstName':
        if (!value) return t('PROFILS.ERROR.FIRSTNAME')
        break
      case 'email':
        if (!value) return t('PROFILS.ERROR.EMAIL')
        if (!/\S+@\S+\.\S+/.test(value.toString()))
          return t('PROFILS.ERROR.INVALID_EMAIL')
        break

      case 'PHOTO':
        if (!value && !form.photoViewMode)
          return t('PROFILS.ERROR.PROFIL_PICTURE')
        break

      case 'identityProof':
        if (!value && userInfo?.user?.role?.id === 4 && !form.identityViewMode)
          return ' Ce champ est obligatoire.'
        break

      case 'insuranceCertificate':
        if (!value && userInfo?.user?.role?.id === 4 && !form.insuranceViewMode)
          return t('PROFILS.ERROR.LABELS.INSURANCE')
        break

      case 'password':
        if (
          value &&
          !/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}$/.test(value.toString())
        )
          return t('PROFILS.ERROR.PASSWORD_MATCH')

        break

      case 'confirmPassword':
        if (!value) return 'La confirmation de mot de passe est requis.'
        if (value && value !== form.password)
          return 'Les mots de passe ne correspondent pas.'
        break

      case 'phone':
        if (!value) return t('PROFILS.ERROR.PHONE_NUMBER')
        if (!parsePhoneNumberFromString(value as any)?.isPossible())
          return t('PROFILS.ERROR.PHONE_INVALIDE')
        break
    }
    return ''
  }

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    const { name, value } = event.target
    const newErrors = { ...errors, [name]: validateField(name, value) }
    setErrors(newErrors)
    setForm({ ...form, [name]: value })
  }

  const handleFormSubmit = async (event: React.FormEvent) => {
    event.preventDefault()

    // Validate all fields before submitting
    let newErrors: FormErrors = {}
    let isValid = true
    for (const [key, value] of Object.entries(form)) {
      const error = validateField(key, value)
      if (error) {
        isValid = false
        newErrors[key] = error
      }
    }

    setErrors(newErrors)
    if (!isValid) {
      console.error('Validation errors', newErrors)
      toast.error(t('PROFILS.ERROR.HANDLE_ERROR'))
      return
    }

    console.log('Form is valid: submitting', form)

    try {
      setLoading(true)
      if (form?.deleteFile && form?.deleteFile > 0) {
        await authService.removeFile(form.deleteFile)
      }
      const updated = await authService.editUserInfo(
        form,
        userInfo?.user?.id || 0,
      )
      if (updated.data) {
        console.log('response', updated.data)
        dispatch(modifyUserInfo(updated.data))
        toast.success(t('PROFILS.ERROR.HANDLE_SUCCESS'))
        setLoading(false)
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        })
      }
    } catch (err) {
      setLoading(false)
      toast.error(t('PROFILS.ERROR.SAVE_FAILLED'))
    }
  }
  const formatPhoneNumber = (phoneNumber: string, country: any) => {
    const parsedPhoneNumber = parsePhoneNumberFromString(
      phoneNumber,
      country || 'FR',
    )
    return parsedPhoneNumber
      ? parsedPhoneNumber.formatInternational()
      : phoneNumber
  }

  const handlePhoneInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    const { name, value } = event.target
    const newErrors = { ...errors, [name]: validateField(name, value) }
    setErrors(newErrors)
    setForm({ ...form, phone: formatPhoneNumber(value, form.country) })
  }

  const handleProfilePhotoDrop = useCallback(
    (acceptedFiles: File[]) => {
      setForm({ ...form, PHOTO: acceptedFiles[0], photoViewMode: true })
    },
    [form],
  ) as any

  const handleSiretDrop = useCallback(
    (acceptedFiles: File[]) => {
      setForm({ ...form, SIRET: acceptedFiles[0], siretViewMode: true })
    },
    [form],
  ) as any

  const convertKBtoMB = (kilobytes: number) => {
    return kilobytes / (1024 * 1024)
  }

  const {
    getRootProps: getProfilePhotoRootProps,
    getInputProps: getProfilePhotoInputProps,
  } = useDropzone({
    onDrop: handleProfilePhotoDrop,
    accept: { 'image/*': ['.jpeg', '.png', '.jpg'] },
    multiple: false,
  })

  const {
    getRootProps: getSiretRootProps,
    getInputProps: getSiretInputProps,
  } = useDropzone({
    onDrop: handleSiretDrop,
    accept: { 'application/pdf': [] },
    multiple: false,
  })

  const handleIdentityDrop = useCallback(
    (acceptedFiles: File[]) => {
      setForm({
        ...form,
        identityProof: acceptedFiles[0],
        identityViewMode: true,
      })
    },
    [form],
  ) as any

  const {
    getRootProps: getIdentityRootProps,
    getInputProps: getIdentityInputProps,
  } = useDropzone({
    onDrop: handleIdentityDrop,
    accept: { 'application/pdf': [] },
    multiple: false,
  })

  const handleInsuranceDrop = useCallback(
    (acceptedFiles: File[]) => {
      setForm({
        ...form,
        insuranceCertificate: acceptedFiles[0],
        insuranceViewMode: true,
      })
    },
    [form],
  ) as any

  const {
    getRootProps: getInsuranceRootProps,
    getInputProps: getInsuranceInputProps,
  } = useDropzone({
    onDrop: handleInsuranceDrop,
    accept: { 'application/pdf': [] },
    multiple: false,
  })
  const togglePasswordVisibility = () => setShowPassword(!showPassword)

  const handleCopyClick = () => {
    const textToCopy = userInfo?.user?.url_sponsorship
    setIsClicked(true)
    navigator.clipboard
      .writeText(textToCopy || '')
      .then(() => {
        setTimeout(() => {
          setIsClicked(false)
        }, 500)
        console.log('Texte copié avec succès')
      })
      .catch((err) => {
        setIsClicked(false)
        console.error('Erreur lors de la copie du texte', err)
      })
  }

  return (
    <>
      <ModaLogout
        show={modalShow}
        onClose={() => {
          setModalShow(false)
        }}
      />
      <main className="main-container main-container--w-bg">
        <div className="container">
          <div className="profil-content">
            <div className="form-wrapper">
              <>
                <h1 className="form-title">{t('PROFILS.TITLE')} </h1>{' '}
                {userInfo?.user.role.id === 3 && (
                  <span className="moderator-title">
                    {t('PROFILS.MODERATOR')}
                  </span>
                )}
                <p className="deconnect">
                  <span
                    onClick={() => {
                      setModalShow(true)
                    }}
                  >
                    {t('PROFILS.LOG_OUT')}
                  </span>
                </p>
                <form onSubmit={handleFormSubmit}>
                  <div
                    className={errors.name ? 'form-item error' : 'form-item'}
                  >
                    <div className="form-item__left">
                      <label>{t('PROFILS.LASTNAME')}</label>
                    </div>
                    <div className="form-item__right">
                      <input
                        type="text"
                        className="form-control"
                        id="name"
                        name="name"
                        value={form.name}
                        onChange={handleInputChange}
                      />
                      {errors.name && (
                        <div className="error-message">{errors.name}</div>
                      )}
                    </div>
                  </div>
                  <div
                    className={
                      errors.firstName ? 'form-item error' : 'form-item'
                    }
                  >
                    <div className="form-item__left">
                      <label>{t('PROFILS.FIRSTNAME')}</label>
                    </div>
                    <div className="form-item__right">
                      <input
                        type="text"
                        className="form-control"
                        id="firstName"
                        name="firstName"
                        value={form.firstName}
                        onChange={handleInputChange}
                      ></input>{' '}
                      {errors.firstName && (
                        <div className="error-message">{errors.firstName}</div>
                      )}
                    </div>
                  </div>
                  <div className="form-item">
                    <div className="form-item__left">
                      <label>{t('PROFILS.EMAIL')}</label>
                    </div>
                    <div className="form-item__right">
                      <input
                        type="text"
                        className="form-control"
                        id="email"
                        name="email"
                        value={form.email}
                        onChange={handleInputChange}
                        readOnly
                      />{' '}
                      {errors.email && (
                        <div className="error-message">{errors.email}</div>
                      )}
                    </div>
                  </div>
                  {/*    <div
                    className={
                      errors.password ? 'form-item error' : 'form-item'
                    }
                  >
                    <div className="form-item__left">
                      <label>{t('PROFILS.PASSWORD')} </label>
                    </div>
                    <div className="form-item__right">
                      <input
                        type={showPassword ? 'text' : 'password'}
                        className="form-control"
                        id="password"
                        name="password"
                        value={form.password}
                        onChange={handleInputChange}
                      ></input>
                      <span
                        className={`password-toggle-icon ${
                          showPassword ? 'password-visible' : ''
                        }`}
                        onClick={togglePasswordVisibility}
                      ></span>
                      {errors.password && (
                        <div className="error-message">{errors.password}</div>
                      )}
                    </div>
                      </div>*/}
                  <div className="form-item">
                    <div className="form-item__left">
                      <label>{t('PROFILS.PHONE_NUMBER')}</label>
                    </div>
                    <div className="form-item__right">
                      <input
                        type="text"
                        className="form-control"
                        id="phone"
                        name="phone"
                        value={form.phone}
                        onChange={handlePhoneInputChange}
                      />{' '}
                      {errors.phone && (
                        <div className="error-message">{errors.phone}</div>
                      )}
                    </div>
                  </div>
                  <div className="form-item">
                    <div className="form-item__left">
                      <label>{t('PROFILS.PROFIL_PICTURE')}</label>
                    </div>

                    <div className="form-item__right">
                      <>
                        {form.photoViewMode ? (
                          <>
                            {' '}
                            <div className="profil__image">
                              {form.PHOTO ? (
                                <img
                                  src={URL.createObjectURL(form.PHOTO)}
                                  alt="Profile"
                                  width="80"
                                />
                              ) : (
                                <img
                                  src={userInfo?.user?.photo}
                                  alt=""
                                  width="80"
                                />
                              )}
                            </div>
                            <span
                              className="profil__close"
                              onClick={() => {
                                setForm({
                                  ...form,
                                  photoViewMode: false,
                                  PHOTO: null,
                                })
                              }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                              >
                                <path
                                  d="M15 9L9 15M9 9L15 15M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
                                  stroke="#98A2B3"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </span>{' '}
                          </>
                        ) : (
                          <>
                            {' '}
                            <div
                              className={`dropzone ${
                                errors.PHOTO ? 'error' : ''
                              } form-field`}
                              {...getProfilePhotoRootProps()}
                            >
                              <input {...getProfilePhotoInputProps()} />
                              {!form.PHOTO && (
                                <>
                                  <div className="file-upload">
                                    <span className="file-upload__icon" />
                                    <p className="file-upload__text">
                                      <span className="click">
                                        {' '}
                                        {t('PROFILS.LABELS.CLICK_IMPORT')}
                                      </span>
                                      {t('PROFILS.LABELS.DRAG_AND_DROP_IMAGE')}
                                    </p>
                                  </div>
                                  {errors.PHOTO && !form.PHOTO && (
                                    <div className="error-message">
                                      {errors.PHOTO}
                                    </div>
                                  )}
                                </>
                              )}
                            </div>
                          </>
                        )}
                      </>
                    </div>
                  </div>
                  <div className="form-item">
                    <div className="form-item__left">
                      <label>{t('PROFILS.PAYS')}</label>
                    </div>
                    <div className="form-item__right">
                      <p className="form-item__desc">
                        {userInfo?.user.lang === 'fr' ? 'France' : 'Portugal'}
                      </p>
                    </div>
                  </div>
                  <div className="form-item">
                    <div className="form-item__left">
                      <label>{t('PROFILS.DEPARTEMENT')}</label>
                    </div>
                    <div className="form-item__right">
                      <p className="form-item__desc">{form?.disctrictName}</p>
                    </div>
                  </div>
                  {userInfo?.user?.role?.id === 4 && (
                    <>
                      <div className="form-item">
                        <div className="form-item__left">
                          <label>{t('PROFILS.LABELS.SIRET')}</label>
                          <span>{t('PROFILS.LABELS.KBIS')}</span>
                        </div>
                        <div className="form-item__right">
                          <>
                            {form.siretViewMode ? (
                              <>
                                {' '}
                                <div className="file-upload file-upload--pdf">
                                  <span className="file-upload__icon--pdf"></span>
                                  <p className="file-upload__text file-upload__text--pdf">
                                    <span className="name">
                                      {form?.SIRET?.name ||
                                        userInfo?.user.siret_file?.name}
                                    </span>
                                    <span className="size">
                                      .pdf |{' '}
                                      {form?.SIRET?.size
                                        ? convertKBtoMB(
                                            form?.SIRET?.size,
                                          ).toFixed(2)
                                        : userInfo?.user.siret_file?.size}
                                      MB
                                    </span>
                                  </p>
                                  <span
                                    className="file-upload__close"
                                    onClick={() => {
                                      setForm({
                                        ...form,
                                        SIRET: null,
                                        siretViewMode: false,
                                        deleteFile:
                                          userInfo?.user.siret_file?.id,
                                      })
                                    }}
                                  ></span>
                                </div>{' '}
                              </>
                            ) : (
                              <>
                                <div
                                  className={`dropzone form-field ${
                                    form.SIRET ? 'drag-active' : ''
                                  }`}
                                  {...getSiretRootProps()}
                                >
                                  <input {...getSiretInputProps()} />
                                  {!form.SIRET && (
                                    <>
                                      <div className="file-upload">
                                        <span className="file-upload__icon"></span>
                                        <p className="file-upload__text">
                                          <span className="click">
                                            {' '}
                                            {t('PROFILS.LABELS.CLICK_IMPORT')}
                                          </span>
                                          {t(
                                            'PROFILS.LABELS.DRAG_AND_DROP_PDF',
                                          )}
                                        </p>
                                      </div>
                                    </>
                                  )}
                                </div>
                              </>
                            )}
                          </>
                        </div>
                      </div>
                      <div className="form-item">
                        <div className="form-item__left">
                          <label>{t('PROFILS.LABELS.IDENTITY')}</label>
                        </div>
                        <div className="form-item__right">
                          <>
                            {form.identityViewMode ? (
                              <>
                                {' '}
                                <div className="file-upload file-upload--pdf">
                                  <span className="file-upload__icon--pdf"></span>
                                  <p className="file-upload__text file-upload__text--pdf">
                                    <span className="name">
                                      {form?.identityProof?.name ||
                                        userInfo?.user.IdFile?.name}
                                    </span>
                                    <span className="size">
                                      .pdf |{' '}
                                      {form?.identityProof?.size
                                        ? convertKBtoMB(
                                            form?.identityProof?.size,
                                          ).toFixed(2)
                                        : userInfo?.user.IdFile?.size}
                                      MB
                                    </span>
                                  </p>
                                  <span
                                    className="file-upload__close"
                                    onClick={() => {
                                      setForm({
                                        ...form,
                                        identityProof: null,
                                        identityViewMode: false,
                                      })
                                    }}
                                  ></span>
                                </div>{' '}
                              </>
                            ) : (
                              <>
                                <div
                                  className={`dropzone ${
                                    errors.identityProof ? 'error' : ''
                                  } form-field ${
                                    form.identityProof ? 'drag-active' : ''
                                  }`}
                                  {...getIdentityRootProps()}
                                >
                                  <input {...getIdentityInputProps()} />
                                  {!form.identityProof && (
                                    <>
                                      <div className="file-upload">
                                        <span className="file-upload__icon"></span>
                                        <p className="file-upload__text">
                                          <span className="click">
                                            {' '}
                                            {t('PROFILS.LABELS.CLICK_IMPORT')}
                                          </span>
                                          {t(
                                            'PROFILS.LABELS.DRAG_AND_DROP_PDF',
                                          )}
                                        </p>
                                      </div>{' '}
                                      {errors.identityProof &&
                                        !form.identityProof && (
                                          <div className="error-message">
                                            {errors.identityProof}
                                          </div>
                                        )}
                                    </>
                                  )}
                                </div>
                              </>
                            )}
                          </>
                        </div>
                      </div>
                      <div className="form-item">
                        <div className="form-item__left">
                          <label>{t('PROFILS.LABELS.INSURANCE')}</label>
                        </div>
                        <div className="form-item__right">
                          <>
                            {form.insuranceViewMode ? (
                              <>
                                {' '}
                                <div className="file-upload file-upload--pdf">
                                  <span className="file-upload__icon--pdf"></span>
                                  <p className="file-upload__text file-upload__text--pdf">
                                    <span className="name">
                                      {form?.insuranceCertificate?.name ||
                                        userInfo?.user.insurance_file?.name}
                                    </span>
                                    <span className="size">
                                      .pdf |{' '}
                                      {form?.insuranceCertificate?.size
                                        ? convertKBtoMB(
                                            form?.insuranceCertificate?.size,
                                          ).toFixed(2)
                                        : userInfo?.user.insurance_file?.size}
                                      MB
                                    </span>
                                  </p>
                                  <span
                                    className="file-upload__close"
                                    onClick={() => {
                                      setForm({
                                        ...form,
                                        insuranceCertificate: null,
                                        insuranceViewMode: false,
                                      })
                                    }}
                                  ></span>
                                </div>{' '}
                              </>
                            ) : (
                              <>
                                <div
                                  className={`dropzone ${
                                    errors.insuranceCertificate ? 'error' : ''
                                  } form-field ${
                                    form.insuranceCertificate
                                      ? 'drag-active'
                                      : ''
                                  }`}
                                  {...getInsuranceRootProps()}
                                >
                                  <input {...getInsuranceInputProps()} />
                                  {!form.insuranceCertificate && (
                                    <>
                                      <div className="file-upload">
                                        <span className="file-upload__icon"></span>
                                        <p className="file-upload__text">
                                          <span className="click">
                                            {' '}
                                            {t('PROFILS.LABELS.CLICK_IMPORT')}
                                          </span>
                                          {t(
                                            'PROFILS.LABELS.DRAG_AND_DROP_PDF',
                                          )}
                                        </p>
                                      </div>{' '}
                                      {errors.insuranceCertificate &&
                                        !form.insuranceCertificate && (
                                          <div className="error-message">
                                            {errors.insuranceCertificate}
                                          </div>
                                        )}
                                    </>
                                  )}
                                </div>
                              </>
                            )}
                          </>
                        </div>
                      </div>
                      <div className="form-item">
                        <div className="form-item__left">
                          <label>{t('PROFILS.LABELS.SPONSORSHIP_LINK')}</label>
                          <span>{t('PROFILS.LABELS.SPONSORSHIP_INFO')}</span>
                        </div>
                        <div className="form-item__right">
                          <div className="form-item__link">
                            <p>
                              {' '}
                              {truncateText(userInfo?.user.url_sponsorship)}
                            </p>
                            <span
                              className={
                                !isClicked ? 'copy-icon' : 'copy-icon-clicked'
                              }
                              onClick={handleCopyClick}
                            />
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  <div className="form-item form-item--no-border">
                    <div className="form-item__left">
                      <label>{t('PROFILS.LABELS.SPONSORSHIP')}</label>
                    </div>
                    {showLoading() ? (
                      <LoaderGameiro
                        bg="loader-custom--transparent"
                        type="loader-custom--relative"
                      />
                    ) : (
                      <>
                        <div className="form-item__right form-item__right--referral">
                          {coptaUser?.length === 0 && (
                            <p className="form-item__desc">
                              {t('PROFILS.LABELS.SPONSORSHIP_ERROR')}
                            </p>
                          )}
                          {coptaUser?.length > 0 && (
                            <div className="referral">
                              <p>{t('PROFILS.LABELS.SPONSORSHIP_LABELS')}</p>
                              <ul
                                className={
                                  coptaUser.length === 2
                                    ? `list-referral with-parental${
                                        lang === 'pt' ? '--pt' : ''
                                      }`
                                    : 'list-referral'
                                }
                              >
                                {coptaUser &&
                                  coptaUser?.map((item, index) => (
                                    <li
                                      key={index}
                                      className="list-referral__item"
                                      onClick={() => {
                                        navigate(`/${lang}/profil/${item?.id}`)
                                      }}
                                    >
                                      <div className="referral__person">
                                        <div className="referral__image">
                                          <img
                                            src={item?.photo}
                                            alt=""
                                            width="40"
                                          />
                                        </div>
                                        <p className="name">
                                          {item?.name} {item?.firstname}
                                        </p>
                                        <span className="referral__icon"></span>
                                      </div>
                                    </li>
                                  ))}
                              </ul>
                            </div>
                          )}

                          {childUser?.length > 0 && (
                            <div className="referral">
                              <p>
                                {t('PROFILS.LABELS.SPONSORSHIP_LABELS_NOW')}{' '}
                              </p>
                              <div className="referral__wrapper">
                                {childUser &&
                                  childUser?.map((item, index) => (
                                    <>
                                      {item.petit_enfant_id ? (
                                        <ul
                                          className={`d-flex align-items-center list-referral with-child${
                                            lang === 'pt' ? '--pt' : ''
                                          }`}
                                        >
                                          <li
                                            className="list-referral__item"
                                            onClick={() => {
                                              navigate(
                                                `/${lang}/profil/${item?.enfant?.id}`,
                                              )
                                            }}
                                          >
                                            <div className="referral__person">
                                              <div className="referral__image">
                                                <img
                                                  src={item?.enfant?.photo}
                                                  alt=""
                                                  width="40"
                                                />
                                              </div>
                                              <p className="name">
                                                {item.enfant?.name}{' '}
                                                {item.enfant?.firstname}{' '}
                                              </p>
                                              <span className="referral__icon"></span>
                                            </div>
                                          </li>
                                          <li
                                            className="list-referral__item"
                                            onClick={() => {
                                              navigate(
                                                `/${lang}/profil/${item?.petit_enfant_id?.id}`,
                                              )
                                            }}
                                          >
                                            <div className="referral__person">
                                              <div className="referral__image">
                                                <img
                                                  src={
                                                    item?.petit_enfant_id?.photo
                                                  }
                                                  alt=""
                                                  width="40"
                                                />
                                              </div>
                                              <p className="name">
                                                {item.petit_enfant_id?.name}
                                                {
                                                  item.petit_enfant_id
                                                    ?.firstname
                                                }{' '}
                                              </p>
                                              <span className="referral__icon"></span>
                                            </div>
                                          </li>
                                        </ul>
                                      ) : (
                                        <ul className="d-flex align-items-center list-referral no-child">
                                          <li
                                            className="list-referral__item"
                                            onClick={() => {
                                              navigate(
                                                `/${lang}/profil/${item?.enfant?.id}`,
                                              )
                                            }}
                                          >
                                            <div className="referral__person">
                                              <div className="referral__image">
                                                <img
                                                  src={item?.enfant.photo}
                                                  alt=""
                                                  width="40"
                                                />
                                              </div>
                                              <p className="name">
                                                {item.enfant?.name}
                                                {item.enfant?.firstname}{' '}
                                              </p>
                                              <span className="referral__icon"></span>
                                            </div>
                                          </li>
                                          <li className="list-referral__item">
                                            <div className="referral__person">
                                              <div className="referral__image">
                                                <img
                                                  src={item?.photo}
                                                  alt=""
                                                  width="40"
                                                />
                                              </div>
                                              <p className="name">
                                                Child : PENA MEME
                                              </p>
                                              <span className="referral__icon"></span>
                                            </div>
                                          </li>
                                        </ul>
                                      )}
                                    </>
                                  ))}
                              </div>
                            </div>
                          )}
                        </div>
                      </>
                    )}
                  </div>{' '}
                  {!loading ? (
                    <>
                      <div className="btn--wrapper d-flex justify-content-center align-item-center">
                        <button
                          type="button"
                          className="btn btn--secondary"
                          onClick={() => {
                            setErrors({})
                            setForm(initialFormState)
                            window.scrollTo({
                              top: 0,
                              behavior: 'smooth',
                            })
                          }}
                        >
                          {t('PROFILS.CANCEL')}
                        </button>{' '}
                        <button type="submit" className="btn btn--primary">
                          {t('PROFILS.SAVE')}
                        </button>{' '}
                      </div>
                    </>
                  ) : (
                    <LoaderGameiro
                      bg="loader-custom--transparent"
                      type="loader-custom--relative"
                    />
                  )}
                </form>{' '}
              </>
            </div>
          </div>
        </div>
      </main>
    </>
  )
}

export default Profil
