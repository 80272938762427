import PurchaseSupportModal from 'components/Modal/PurchaseSupport/PurchaseSupportModal'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'
import { Product, setSelectedProduct } from 'stores/slices/product-slice'
import { Support, setSelectedSupport } from 'stores/slices/support-slice'
import { ArrowUpRight1 } from './ArrowUpRight'
import './style.scss'

interface ProductItemProps {
  product?: Product
  isFrom?: string
  support?: Support
}

const ProductItem: React.FC<ProductItemProps> = ({
  product,
  isFrom,
  support,
}) => {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    swipe: false,
    dots: true,
    centerMode: false,
    adaptiveHeight: true,
    beforeChange: (_current: number, next: number) => {
      setActiveSlide(next)
    },
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }
  const [modalShow, setModalShow] = useState(false)
  const { lang } = useParams()
  const [activeSlide, setActiveSlide] = useState(0)
  const dispatch = useDispatch()
  const handleModalOpen = () => {
    if (isFrom === 'purchas') {
      dispatch(setSelectedProduct(product as any))
    } else {
      dispatch(setSelectedSupport(support as any))
    }
    setModalShow(true)
  }

  const handleModalClose = () => {
    dispatch(setSelectedProduct(null))
    dispatch(setSelectedSupport(null))
    setModalShow(false)
    setActiveSlide(0)
  }

  React.useEffect(() => {
    if (isFrom) {
      setActiveSlide(0)
    }
  }, [isFrom])

  return (
    <>
      <div className="product-item__col">
        <div className="product-item">
          {product && (
            <>
              <div className="product-item__image">
                <Slider {...settings}>
                  {product?.photos.map((photo: any) => (
                    <>
                      <img
                        src={photo?.url}
                        onClick={handleModalOpen}
                        alt={photo?.title}
                        className="product-img"
                      />{' '}
                    </>
                  ))}{' '}
                </Slider>
              </div>
              <div className="product-item__content">
                {isFrom === 'purchas' && (
                  <p className="product-item__price">
                    {product?.nbExemplaire} pour {product.price} €{' '}
                  </p>
                )}
                <div className="product-item__icons" onClick={handleModalOpen}>
                  <h3 className="heading-3">{product.title} </h3>
                  <div className="icon-wrap">
                    <ArrowUpRight1 className="arrow-up-right" color="#316845" />
                  </div>
                </div>
              </div>
            </>
          )}

          {support && (
            <>
              <div className="product-item__image">
                <Slider {...settings}>
                  {support?.photo?.map((photo: any) => (
                    <>
                      <img
                        src={photo?.url}
                        onClick={handleModalOpen}
                        alt={support?.title}
                        className="card-img-top"
                      />{' '}
                    </>
                  ))}{' '}
                </Slider>
              </div>
              <div className="product-item__content" onClick={handleModalOpen}>
                <div className="product-item__icons">
                  <div className="heading">{support?.title}</div>
                  <div className="icon-wrap">
                    <ArrowUpRight1 className="arrow-up-right" color="#316845" />
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>

      <PurchaseSupportModal
        isFrom={isFrom}
        locale={lang || 'fr'}
        show={modalShow}
        activeSlide={activeSlide}
        onOpen={handleModalOpen}
        onClose={handleModalClose}
      />
    </>
  )
}

export default ProductItem
