import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import purchaseSupportService from 'services/purchase-support.service';
import { RootState } from 'stores/store';

interface Photo {
  url: string;
}

export interface Product {
  id: number;
  title: string;
  locale?: string;
  price: number | null;
  descriptions: string;
  nbExemplaire: number;
  photos: Photo[];
}

interface ProductsState {
  products: Product[];
  selectedProduct: Product | null;
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
}

const initialState: ProductsState = {
  products: [],
  selectedProduct: null,
  status: 'idle',
  error: null,
};

export const fetchProducts = createAsyncThunk(
  'products/fetchProducts',
  async (locale: string) => {
    const response = await purchaseSupportService.listAchat(locale);

    return response;
  }
);

const productsSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    setSelectedProduct: (state, action: PayloadAction<Product | null>) => {
      state.selectedProduct = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProducts.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchProducts.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.products = action?.payload;
      })
      .addCase(fetchProducts.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action?.error?.message ?? "Une erreur s'est produite.";
      });
  },
});

export const { setSelectedProduct } = productsSlice.actions;

export const getSelectedProduct = (state: RootState) =>
  state.products.selectedProduct;

export const selectProducts = (state: RootState) => state.products;

export default productsSlice.reducer;
