import { TabSwitcher } from 'components/TabSwitcher'
import React from 'react'

const DiscussionList: React.FC = () => {
  return (
    <>
      <TabSwitcher />
    </>
  )
}

export default DiscussionList
