import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import fr from './fr/translation.json';
import pt from './pt/translation.json';
i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    resources: {
      fr: {
        translation: fr,
      },
      pt: {
        translation: pt,
      },
    },
    lng: 'fr',
    fallbackLng: 'fr',
    interpolation: {
      escapeValue: false,
    },
  });
