import App from 'App'
import ProtectedRoute from 'components/ProtectedRoute/ProtectedRoute'
import CreateDiscussion from 'pages/Discussion/Create'
import DetailsDiscussion from 'pages/Discussion/Details'
import Discussion from 'pages/Discussion/Discussion'
import Login from 'pages/Login/Login'
import Profil from 'pages/Profil/Profil'
import ProfilViewMode from 'pages/Profil/ProfilView'
import PurchasingCenter from 'pages/PurchasingCenter/ProductPage'
import Register from 'pages/Register/Register'
import Support from 'pages/Support/Support'
import React from 'react'
import { createBrowserRouter } from 'react-router-dom'
const AppWithEffect: React.FC = () => {
  return <App />
}

export const router = createBrowserRouter([
  {
    path: '/',
    element: <AppWithEffect />,
    children: [
      { path: '', element: <Login /> },
      { path: '/:lang', element: <Login /> },
      { path: '/:lang/login', element: <Login /> },
      { path: '/:lang/register', element: <Register /> },
      { path: '/:lang/moderator/register', element: <Register /> },
      { path: '/:lang/moderator/register', element: <Register /> },
      { path: '/:lang/register-account/:code', element: <Register /> },
    ],
  },

  {
    element: <ProtectedRoute />,
    children: [
      { path: '/:lang/purchasing', element: <PurchasingCenter /> },
      { path: '/:lang/discussion', element: <Discussion /> },
      {
        path: '/:lang/discussion-details/:slug',
        element: <DetailsDiscussion />,
      },
      { path: '/:lang/discussion/create', element: <CreateDiscussion /> },
      { path: '/:lang/profil', element: <Profil /> },
      { path: '/:lang/profil/:username', element: <ProfilViewMode /> },
      { path: '/:lang/support', element: <Support /> },
    ],
  },
])
