import React from "react";
import { useTranslation } from "react-i18next";
import responseService from "services/response.service";
import "./style.scss";

interface ModalComponentProps {
  show: boolean;
  onClose: (isData?: string) => void;
  onOpen?: () => void;
  idResponse: number | string;
  isMyAnswer: boolean;
}

const ModalComponent: React.FC<ModalComponentProps> = ({
  show,
  onClose,
  idResponse,
  isMyAnswer,
}) => {
  const { t, i18n } = useTranslation();
  if (!show) return null;

  const handleClose = () => {
    onClose("");
  };

  const handleCloseDelete = async () => {
    const data = await responseService.delete(idResponse?.toString());

    if (data) {
      onClose("true");
    }
  };

  return (
    <>
      <div
        className="modal-overlay"
        onClick={() => {
          handleClose();
        }}
      ></div>
      <div className="modal_delete">
        <div className="modal-content_">
          <div className="modal-header"></div>
          <div className="modal-body">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="248"
              height="196"
              viewBox="0 0 248 196"
              fill="none"
            >
              <g clip-path="url(#clip0_428_8135)">
                <path
                  opacity="0.1"
                  d="M241.296 155.18C245.912 126.755 211.491 48.6853 178.27 23.2073C135.188 -9.83415 78.9592 3.19473 53.7779 61.2242C30.377 115.106 56.1725 170.382 103.002 188.966C144.676 205.522 237.155 189.677 241.296 155.18Z"
                  fill="#004519"
                />
                <path
                  d="M169.737 165.876H27.248C25.4527 165.841 23.7328 165.148 22.4139 163.93C21.095 162.711 20.2688 161.051 20.0917 159.265L12.066 62.7972C11.9803 61.9546 12.0737 61.1034 12.3403 60.2995C12.6069 59.4956 13.0406 58.7572 13.6128 58.1328C14.1851 57.5085 14.883 57.0122 15.6606 56.6767C16.4383 56.3412 17.2781 56.174 18.125 56.1862H160.607C162.403 56.2212 164.124 56.9133 165.444 58.1316C166.764 59.3499 167.592 61.0098 167.771 62.7972L175.796 159.251C175.883 160.094 175.79 160.947 175.524 161.752C175.258 162.557 174.825 163.296 174.253 163.922C173.68 164.548 172.982 165.045 172.204 165.382C171.426 165.719 170.585 165.887 169.737 165.876Z"
                  fill="white"
                />
                <path
                  d="M169.736 166.152H27.2464C25.3812 166.121 23.5924 165.405 22.2216 164.14C20.8507 162.875 19.9937 161.149 19.8142 159.292L11.7885 62.8315C11.7074 61.9514 11.811 61.0641 12.0927 60.2263C12.3743 59.3885 12.8277 58.6188 13.424 57.9664C14.0172 57.3096 14.7425 56.7857 15.5524 56.4289C16.3623 56.0721 17.2385 55.8906 18.1235 55.8961H160.606C162.479 55.9258 164.275 56.647 165.648 57.9209C167.021 59.1949 167.875 60.9316 168.045 62.797L176.064 159.251C176.153 160.13 176.054 161.019 175.775 161.858C175.497 162.697 175.043 163.467 174.446 164.119C173.848 164.77 173.12 165.288 172.308 165.639C171.496 165.989 170.62 166.164 169.736 166.152ZM18.1235 56.4758C17.3134 56.4626 16.5098 56.6215 15.7657 56.9418C15.0216 57.2621 14.3539 57.7367 13.8067 58.3341C13.2595 58.9315 12.8453 59.6382 12.5914 60.4075C12.3374 61.1768 12.2496 61.9912 12.3336 62.797L20.3594 159.244C20.5301 160.965 21.3274 162.564 22.5997 163.735C23.8721 164.907 25.5307 165.571 27.2602 165.599H169.736C170.544 165.611 171.346 165.452 172.088 165.132C172.83 164.812 173.496 164.339 174.042 163.743C174.589 163.147 175.003 162.443 175.257 161.676C175.511 160.908 175.601 160.096 175.519 159.292L167.493 62.8315C167.324 61.1096 166.527 59.5102 165.254 58.3381C163.982 57.166 162.322 56.5031 160.592 56.4758H18.1235Z"
                  fill="#004519"
                />
                <path
                  d="M160.606 56.2003H18.1236C17.278 56.1884 16.4395 56.3552 15.6628 56.6899C14.8862 57.0246 14.1891 57.5195 13.6171 58.1424C13.045 58.7652 12.611 59.5019 12.3435 60.3041C12.076 61.1063 11.9809 61.956 12.0646 62.7975H167.769C167.587 61.0126 166.758 59.356 165.439 58.1406C164.119 56.9251 162.4 56.2349 160.606 56.2003Z"
                  fill="#004519"
                />
                <path
                  d="M21.4563 59.5057C21.4759 59.6876 21.4566 59.8715 21.3997 60.0453C21.3428 60.2192 21.2496 60.3789 21.1263 60.514C21.0029 60.6491 20.8523 60.7564 20.6843 60.8288C20.5164 60.9013 20.3349 60.9372 20.1521 60.9342C19.7644 60.926 19.3931 60.7762 19.1083 60.513C18.8235 60.2498 18.6449 59.8915 18.6063 59.5057C18.5878 59.3241 18.6079 59.1406 18.6652 58.9672C18.7225 58.7939 18.8158 58.6346 18.9389 58.4997C19.062 58.3649 19.2122 58.2576 19.3796 58.1848C19.547 58.112 19.728 58.0753 19.9105 58.0772C20.2991 58.0824 20.672 58.2312 20.9574 58.4949C21.2429 58.7587 21.4206 59.1187 21.4563 59.5057Z"
                  fill="white"
                />
                <path
                  d="M27.2529 59.5057C27.2725 59.6876 27.2532 59.8715 27.1963 60.0453C27.1394 60.2192 27.0462 60.3789 26.9229 60.514C26.7996 60.6491 26.6489 60.7564 26.481 60.8288C26.313 60.9013 26.1316 60.9372 25.9487 60.9342C25.5658 60.9194 25.2012 60.7667 24.9222 60.5041C24.6432 60.2416 24.4685 59.8869 24.4305 59.5057C24.412 59.3241 24.4321 59.1406 24.4894 58.9672C24.5467 58.7939 24.64 58.6346 24.7631 58.4997C24.8862 58.3649 25.0364 58.2576 25.2038 58.1848C25.3713 58.112 25.5522 58.0753 25.7347 58.0772C26.1185 58.0891 26.4847 58.2409 26.7643 58.504C27.044 58.7671 27.2178 59.1234 27.2529 59.5057Z"
                  fill="white"
                />
                <path
                  d="M33.0501 59.5057C33.0697 59.6876 33.0504 59.8715 32.9935 60.0453C32.9366 60.2192 32.8433 60.3789 32.72 60.514C32.5967 60.6491 32.446 60.7564 32.2781 60.8288C32.1101 60.9013 31.9287 60.9372 31.7458 60.9342C31.3581 60.926 30.9869 60.7762 30.7021 60.513C30.4173 60.2498 30.2387 59.8915 30.2 59.5057C30.1815 59.3241 30.2016 59.1406 30.259 58.9672C30.3163 58.7939 30.4095 58.6346 30.5326 58.4997C30.6557 58.3649 30.8059 58.2576 30.9734 58.1848C31.1408 58.112 31.3217 58.0753 31.5043 58.0772C31.8929 58.0824 32.2658 58.2312 32.5512 58.4949C32.8366 58.7587 33.0143 59.1187 33.0501 59.5057Z"
                  fill="white"
                />
                <path
                  d="M157.37 153.233H37.5435C36.1162 153.207 34.7484 152.657 33.6991 151.689C32.6498 150.721 31.992 149.402 31.8503 147.982L26.2192 80.7395C26.1519 80.0689 26.2273 79.3916 26.4405 78.7523C26.6537 78.1129 26.9998 77.5258 27.4561 77.0298C27.9124 76.5337 28.4685 76.1399 29.0879 75.8742C29.7073 75.6084 30.3759 75.4768 31.0498 75.488H150.897C152.325 75.5147 153.693 76.0641 154.742 77.032C155.791 77.9999 156.449 79.319 156.591 80.7395L162.187 148.016C162.249 148.683 162.17 149.355 161.956 149.99C161.741 150.624 161.395 151.206 160.941 151.698C160.487 152.19 159.934 152.581 159.319 152.845C158.704 153.11 158.04 153.242 157.37 153.233Z"
                  fill="#F0F0F0"
                />
                <path
                  opacity="0.1"
                  d="M120.301 87.2327L83.1741 131.937C82.0072 133.34 81.2218 135.021 80.8937 136.817C80.5656 138.613 80.7058 140.462 81.301 142.188C81.8961 143.914 82.9259 145.457 84.2912 146.669C85.6564 147.881 87.3109 148.72 89.095 149.106L149.34 162.128C151.224 162.537 153.184 162.425 155.009 161.804C156.835 161.184 158.457 160.078 159.702 158.605C160.946 157.133 161.767 155.349 162.075 153.446C162.383 151.543 162.167 149.592 161.451 147.802L138.354 90.0689C137.671 88.3559 136.555 86.85 135.114 85.6994C133.673 84.5489 131.957 83.7933 130.135 83.5071C128.314 83.2209 126.449 83.4139 124.725 84.0672C123 84.7204 121.476 85.8114 120.301 87.2327Z"
                  fill="#263238"
                />
                <path
                  opacity="0.1"
                  d="M37.6551 93.4985L24.7228 128.355C24.3355 129.419 24.224 130.564 24.3985 131.683C24.5731 132.802 25.0281 133.858 25.7212 134.754C26.4143 135.65 27.3228 136.355 28.3622 136.805C29.4015 137.255 30.5378 137.434 31.6651 137.326L69.4336 133.62C70.6355 133.503 71.7834 133.063 72.7562 132.348C73.7289 131.632 74.4905 130.667 74.9605 129.555C75.4304 128.442 75.5914 127.224 75.4263 126.028C75.2613 124.831 74.7764 123.702 74.0227 122.758L49.1796 91.6352C48.4374 90.7089 47.4626 89.996 46.3549 89.5695C45.2471 89.143 44.046 89.0181 42.8742 89.2076C41.7024 89.397 40.6018 89.894 39.6849 90.6479C38.768 91.4017 38.0676 92.3854 37.6551 93.4985Z"
                  fill="#263238"
                />
                <path
                  d="M133.106 75.9775L98.3949 126.354C97.3108 127.927 96.6486 129.753 96.4718 131.655C96.2949 133.558 96.6093 135.474 97.3848 137.221C98.1604 138.967 99.3712 140.485 100.901 141.63C102.431 142.774 104.23 143.507 106.124 143.758L169.929 152.253C171.936 152.52 173.978 152.237 175.836 151.433C177.694 150.63 179.299 149.336 180.478 147.691C181.658 146.046 182.368 144.111 182.532 142.093C182.697 140.075 182.31 138.051 181.413 136.236L152.318 77.3439C151.455 75.5992 150.15 74.1106 148.534 73.0258C146.917 71.941 145.045 71.2976 143.104 71.1595C141.162 71.0214 139.218 71.3934 137.464 72.2385C135.71 73.0836 134.208 74.3726 133.106 75.9775Z"
                  fill="#004519"
                />
                <path
                  d="M107.636 139.258C106.483 139.102 105.388 138.663 104.446 137.98C103.505 137.297 102.746 136.392 102.239 135.346C101.716 134.356 101.471 133.244 101.528 132.127C101.585 131.009 101.943 129.928 102.564 128.997L137.365 78.4686C137.994 77.5673 138.864 76.8614 139.876 76.4318C140.888 76.0022 142 75.8662 143.086 76.0395C144.238 76.195 145.335 76.6338 146.276 77.3166C147.218 77.9994 147.976 78.9049 148.482 79.9523L177.631 138.989C178.154 139.979 178.4 141.091 178.343 142.208C178.286 143.326 177.928 144.407 177.307 145.338C176.679 146.241 175.809 146.949 174.797 147.38C173.785 147.811 172.672 147.948 171.586 147.774L107.636 139.258Z"
                  fill="white"
                />
                <path
                  d="M140.125 93.8989L144.19 94.4371C145.46 94.6164 146.621 95.2571 147.449 96.2372C148.278 97.2172 148.717 98.4679 148.682 99.7508L148.406 105.051C148.399 105.243 148.379 105.434 148.344 105.623L145.453 121.599C145.245 122.7 144.617 123.677 143.7 124.321C142.784 124.966 141.652 125.227 140.546 125.049C139.372 124.883 138.289 124.323 137.473 123.462C136.657 122.602 136.157 121.49 136.054 120.309L134.887 103.802C134.874 103.611 134.874 103.42 134.887 103.229L135.156 97.9221C135.185 97.3192 135.342 96.7293 135.615 96.191C135.887 95.6526 136.271 95.178 136.74 94.7982C137.209 94.4184 137.753 94.1419 138.336 93.9869C138.92 93.832 139.529 93.802 140.125 93.8989ZM139.801 129.48L140.905 129.625C142.042 129.785 143.081 130.359 143.823 131.235C144.564 132.112 144.958 133.231 144.928 134.379C144.904 134.921 144.765 135.451 144.522 135.935C144.278 136.419 143.935 136.846 143.515 137.189C143.094 137.531 142.606 137.78 142.083 137.92C141.559 138.061 141.012 138.089 140.477 138.002L139.373 137.851C138.236 137.692 137.198 137.12 136.456 136.244C135.714 135.368 135.32 134.25 135.35 133.103C135.373 132.561 135.511 132.03 135.754 131.546C135.997 131.061 136.34 130.633 136.761 130.291C137.181 129.949 137.67 129.699 138.194 129.559C138.718 129.42 139.265 129.393 139.801 129.48Z"
                  fill="#004519"
                />
                <path
                  d="M51.0006 83.6788L41.4705 119.819C41.1831 120.917 41.1791 122.07 41.4589 123.17C41.7387 124.27 42.2931 125.281 43.0702 126.109C43.8473 126.936 44.8217 127.553 45.9022 127.901C46.9826 128.249 48.1338 128.318 49.2478 128.1L86.2158 120.888C87.3887 120.654 88.4778 120.111 89.3701 119.315C90.2624 118.518 90.9256 117.498 91.2909 116.359C91.6562 115.22 91.7104 114.004 91.4478 112.837C91.1852 111.671 90.6154 110.595 89.7974 109.723L62.3871 80.7735C61.565 79.8999 60.5239 79.2626 59.372 78.9277C58.2202 78.5927 56.9996 78.5725 55.8373 78.8691C54.675 79.1657 53.6133 79.7682 52.7627 80.614C51.9121 81.4599 51.3037 82.5181 51.0006 83.6788Z"
                  fill="#004519"
                />
                <path
                  d="M49.1712 125.187C48.4925 125.31 47.7936 125.26 47.1397 125.04C46.4858 124.82 45.898 124.439 45.431 123.931C44.9491 123.443 44.6008 122.838 44.4196 122.176C44.2383 121.514 44.2302 120.816 44.3958 120.15L53.9466 83.9133C54.1122 83.2801 54.4545 82.7071 54.9335 82.2611C55.4125 81.8151 56.0084 81.5145 56.6518 81.3945C57.3311 81.2731 58.0301 81.3252 58.684 81.546C59.3378 81.7667 59.9253 82.149 60.3921 82.6573L87.899 111.641C88.3815 112.13 88.7306 112.734 88.9131 113.396C89.0955 114.058 89.1051 114.756 88.941 115.423C88.7743 116.055 88.4315 116.626 87.9526 117.071C87.4737 117.516 86.8784 117.815 86.2359 117.935L49.1712 125.187Z"
                  fill="white"
                />
                <path
                  d="M58.4463 92.4226L60.7995 91.9603C61.5593 91.8376 62.3376 92.0003 62.9847 92.4168C63.6319 92.8334 64.1022 93.4745 64.3052 94.2168L65.1471 97.3706C65.179 97.4864 65.2043 97.6039 65.223 97.7225L66.7205 107.653C66.8372 108.321 66.6852 109.009 66.2974 109.566C65.9097 110.123 65.3177 110.504 64.6502 110.627C63.9503 110.743 63.2316 110.616 62.6139 110.267C61.9962 109.918 61.5166 109.368 61.255 108.709L57.4319 99.2683C57.3855 99.1582 57.3463 99.0453 57.3146 98.9302L56.4451 95.7695C56.332 95.4182 56.2952 95.0468 56.3371 94.6801C56.379 94.3134 56.4986 93.9598 56.6881 93.6431C56.8775 93.3263 57.1323 93.0536 57.4355 92.8431C57.7387 92.6326 58.0833 92.4893 58.4463 92.4226ZM65.1057 113.318L65.7474 113.194C66.428 113.076 67.1281 113.218 67.7094 113.591C68.2907 113.964 68.711 114.542 68.8874 115.209C68.9846 115.52 69.0152 115.848 68.9772 116.172C68.9392 116.496 68.8334 116.808 68.6667 117.088C68.5 117.368 68.2762 117.609 68.0098 117.797C67.7434 117.985 67.4405 118.114 67.1207 118.177L66.4789 118.301C65.7993 118.419 65.1 118.278 64.5189 117.907C63.9377 117.535 63.5168 116.959 63.339 116.293C63.2418 115.981 63.2111 115.653 63.2491 115.329C63.2871 115.005 63.3928 114.692 63.5594 114.412C63.726 114.131 63.9498 113.889 64.2162 113.701C64.4826 113.512 64.7857 113.382 65.1057 113.318Z"
                  fill="#004519"
                />
              </g>
              <defs>
                <clipPath id="clip0_428_8135">
                  <rect
                    width="247.5"
                    height="195.088"
                    fill="white"
                    transform="translate(0.25)"
                  />
                </clipPath>
              </defs>
            </svg>
            <h4>
              {isMyAnswer
                ? t("DETAILS.LABELS.DELETE_INFO")
                : t("DETAILS.LABELS.DELETE_CONFIRM")}
            </h4>
            <p>{t("DETAILS.LABELS.ACTION_INFO")}</p>
          </div>
          <div className="modal-footer__delete">
            <button
              onClick={() => {
                handleCloseDelete();
              }}
              className="btn btn--primary btn-confirm"
            >
              {t("DETAILS.LABELS.CONFIRM")}
            </button>
            <button
              onClick={() => {
                handleClose();
              }}
              className="btn btn--light btn-cancel"
            >
              {t("DETAILS.BUTTON.CANCEL")}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ModalComponent;
