import React from 'react'
import './style.scss'

const CreateDiscussion: React.FC = () => {
  return (
    <div className="container my-3 product-grid">
      <h1>Create Discussions</h1>
    </div>
  )
}

export default CreateDiscussion
