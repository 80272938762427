import RegisterForm from 'components/RegisterForm/RegisterForm'
import React from 'react'

const Register: React.FC = () => {
  return (
    <>
      <main className="main-container main-container--w-bg">
        <div className="login-container main-container__inner">
          <div className="container">
            <RegisterForm />
          </div>
        </div>  
      </main>
    </>
  )
}

export default Register
