import LoaderGameiro from 'components/LoaderGameiro/Loader'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import authService from 'services/auth.service'
import { storageService } from 'services/storage.service'
import { setUserInfo } from 'stores/slices/user-slice'
import './style.scss'

const Login: React.FC = () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState({ email: '', password: '' })
  const [errorAPI, setErrorApi] = useState(false)
  const [messageError, setMessageError] = useState('')
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const togglePasswordVisibility = () => setShowPassword(!showPassword)

  const validateForm = () => {
    let valid = true
    let newErrors = { email: '', password: '' }

    if (!email) {
      newErrors.email = t('ERRORS.AUTH.INVALID_EMAIL')
      valid = false
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)) {
      newErrors.email = t('ERRORS.AUTH.INVALID_EMAIL')
      valid = false
    }

    if (!password) {
      newErrors.password = t('ERRORS.AUTH.INVALID_PASSWORD')
      valid = false
    }

    setErrors(newErrors)
    return valid
  }

  const handleLogin = (event: React.FormEvent) => {
    event.preventDefault()
    if (validateForm()) {
      setLoading(true)
      setErrorApi(false)
      authService
        .login(email, password)
        .then((res) => {
          setLoading(false)
          console.log('res', res.data)
          storageService.setSessionStorageObject('users', res)
          dispatch(setUserInfo(res))
          console.log('res', res)
        })
        .catch((err) => {
          setErrorApi(true)
          if (
            err?.response?.data?.error?.message === 'Your account not confirmed'
          ) {
            setMessageError(t('ERRORS.AUTH.USER_DISABLED'))
            toast.error(t('ERRORS.AUTH.USER_DISABLED'))
          } else {
            toast.error(t('ERRORS.AUTH.EMAIL_NOT_FOUND'))
            setMessageError(t('ERRORS.AUTH.EMAIL_NOT_FOUND'))
          }
          setLoading(false)
        })
    }
  }

  return (
    <>
      <main className="main-container main-container--w-bg">
        <div className="login-container main-container__inner">
          <div className="container">
            <div className="form-wrapper">
              <form
                className="login-form form-w-padding"
                onSubmit={handleLogin}
              >
                <h1 className="form-title text-center">{t('LOGIN.TITLE')}</h1>
                <div className= {errors.email?"form-field error" :"form-field" }>
                  <label htmlFor="email">{t('PROFILS.EMAIL')}</label>
                  <input
                    type="text"
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    onFocus={() =>
                      setErrors((prevErrors) => ({ ...prevErrors, email: '' }))
                    }
                  />
                  {errors.email && (
                    <div className="error-message">{errors.email}</div>
                  )}
                </div>
                <div className= {errors.password?"form-field error" :"form-field" }>
                  <label htmlFor="password">{t('PROFILS.PASSWORD')}</label>
                  <div className="password-input">
                    <input
                      type={showPassword ? 'text' : 'password'}
                      id="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      onFocus={() =>
                        setErrors((prevErrors) => ({
                          ...prevErrors,
                          password: '',
                        }))
                      }
                    />
                    <span
                      className={`password-toggle-icon ${
                        showPassword ? 'password-visible' : 'password-hidden'
                      }`}
                      onClick={togglePasswordVisibility}
                    ></span>
                  </div>
                  {errorAPI && (
                    <div className="error-message">{messageError}</div>
                  )}
                  {errors.password && (
                    <div className="error-message">{errors.password}</div>
                  )}
                </div>
                {!loading ? (
                  <div className="form-button">
                    <button type="submit" className="btn btn--primary btn--full">
                      {t('LOGIN.BUTTON')}
                    </button>
                  </div>
                ) : (
                  <LoaderGameiro
                    bg="loader-custom--transparent"
                    type="loader-custom--relative"
                  />
                )}
              </form>
            </div>
          </div>
        </div>
      </main>
    </>
  )
}

export default Login
