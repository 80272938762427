import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface SessionState {
  isTokenExpired: boolean;
}

const initialState: SessionState = {
  isTokenExpired: false,
};

const sessionSlice = createSlice({
  name: 'session',
  initialState,
  reducers: {
    setTokenExpired: (state, action: PayloadAction<boolean>) => {
      state.isTokenExpired = action.payload;
    },
  },
});

export const { setTokenExpired } = sessionSlice.actions;
export default sessionSlice.reducer;
