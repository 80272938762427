/* eslint-disable jsx-a11y/alt-text */
import LoaderGameiro from 'components/LoaderGameiro/Loader'
import { saveAs } from 'file-saver'
import React, { useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import Slider from 'react-slick'
import { toast } from 'react-toastify'
import orderService from 'services/order.service'
import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'
import { getSelectedProduct } from 'stores/slices/product-slice'
import { getSelectedSupport } from 'stores/slices/support-slice'
import { selectUserInfo } from 'stores/slices/user-slice'

import './style.scss'

interface PurchaseSupportModalProps {
  isFrom?: string
  show: boolean
  activeSlide?: number
  locale?: string
  onClose: () => void
  onOpen: () => void
}

const PurchaseSupportModal: React.FC<PurchaseSupportModalProps> = ({
  show,
  locale,
  onClose,
  isFrom,
  activeSlide,
}) => {
  const selectedProduct = useSelector(getSelectedProduct)
  const selectedSupport = useSelector(getSelectedSupport)
  const userInfo = useSelector(selectUserInfo)
  const [loading, setLoading] = useState(false)
  const settings = {
    infinite: true,
    speed: 500,
    initialSlide: activeSlide,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    dots: true,
    centerMode: false,
    adaptiveHeight: true,
    swipe: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }

  const order = async () => {
    try {
      setLoading(true)
      const data = await orderService.confirmOrder({
        name: userInfo?.user.name,
        locale: locale,
        firstname: userInfo?.user.firstname,
        productName: selectedProduct?.title || '',
        price: `${selectedProduct?.nbExemplaire} ${
          locale === 'fr' ? 'pour' : 'por'
        }
      ${selectedProduct?.price} €`,
      })

      if (data) {
        toast.success(
          'Votre commande a été traitée avec succès. Un administrateur vous contactera prochainement pour confirmer les détails',
        )
        setLoading(false)
        onClose()
      }
    } catch (error) {
      setLoading(false)
      toast.error(
        'Désolé, une erreur est survenue lors du traitement de votre commande. Veuillez réessayer ultérieurement ou contacter le support technique.',
      )
    }
  }

  const download = async () => {
    const url = selectedSupport?.file_source
    const fileName = url ? url.substring(url?.lastIndexOf('/') + 1) : 'file'
    try {
      saveAs(url || '', fileName)
      onClose()
    } catch (error) {
      onClose()
      console.error('Erreur lors du téléchargement du fichier :', error)
    }
  }

  return (
    <Modal
      show={show}
      onHide={onClose}
      dialogClassName="modal-right"
      size="lg"
      className="info-modal"
    >
      <Modal.Header>
        <div className="btn-back" onClick={onClose}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M12 19L5 12M5 12L12 5M5 12H19"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <span className="btn-back__text">Retour</span>
        </div>
        <Modal.Title>
          <h4 className="heading-4">
            {isFrom === 'purchas'
              ? selectedProduct?.title
              : selectedSupport?.title}{' '}
          </h4>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isFrom === 'purchas' ? (
          <>
            <div className="modal-img">
              <Slider {...settings}>
                {selectedProduct?.photos?.map((photo: any) => (
                  <>
                    <img
                      src={photo?.url}
                      alt={photo?.title}
                      className="product-img"
                    />{' '}
                  </>
                ))}{' '}
              </Slider>
            </div>
            {/* <h3 className="product-title">{selectedProduct?.title}</h3> */}
            <div className="modal-desc">
              <ul className="modal-desc__list">
                <li>
                  <p className="modal-desc__title">Description : </p>
                  <p className="modal-desc__text">
                    {selectedProduct?.descriptions}
                  </p>
                </li>
                <li>
                  <p className="modal-desc__title">Price:</p>
                  <p className="modal-desc__text">
                    {selectedProduct?.nbExemplaire}{' '}
                    {locale === 'fr' ? 'pour' : 'por'} {selectedProduct?.price}{' '}
                    €
                  </p>
                </li>
              </ul>
            </div>{' '}
          </>
        ) : (
          <>
            <div className="modal-img">
              <Slider {...settings}>
                {selectedSupport?.photo?.map((photo: any) => (
                  <>
                    <img src={photo?.url} alt={photo?.url} />{' '}
                  </>
                ))}{' '}
              </Slider>
            </div>
            <div className="modal-desc">
              <ul className="modal-desc__list">
                <li>
                  <p className="modal-desc__title">Description : </p>
                  <p className="modal-desc__text">
                    {selectedSupport?.descriptions}
                  </p>
                </li>
              </ul>
            </div>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        {isFrom === 'purchas' ? (
          <>
            {!loading ? (
              <Button
                className="btn btn--primary w-100"
                variant="secondary"
                onClick={order}
              >
                Commander
              </Button>
            ) : (
              <LoaderGameiro
                bg="loader-custom--transparent"
                type="loader-custom--relative"
              />
            )}
          </>
        ) : (
          <>
            <Button
              className="btn btn--primary btn--link btn--full"
              variant="secondary"
              onClick={download}
            >
              Telecharger
              <span className="icon--download">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="17"
                  viewBox="0 0 16 17"
                  fill="none"
                >
                  <g clip-path="url(#clip0_1149_4625)">
                    <path
                      d="M5.3328 11.5688L7.99947 14.2355M7.99947 14.2355L10.6661 11.5688M7.99947 14.2355V8.23547M13.9195 12.2955C14.4991 11.8879 14.9337 11.3062 15.1603 10.6349C15.3869 9.96357 15.3937 9.23749 15.1797 8.56202C14.9657 7.88656 14.542 7.29687 13.9702 6.87852C13.3983 6.46016 12.708 6.23492 11.9995 6.23547H11.1595C10.959 5.45406 10.5838 4.72831 10.0622 4.11286C9.54063 3.49742 8.88623 3.00831 8.14827 2.68238C7.41031 2.35644 6.60803 2.20216 5.80182 2.23115C4.99561 2.26015 4.20648 2.47166 3.49384 2.84976C2.7812 3.22786 2.16362 3.76271 1.68759 4.41403C1.21157 5.06535 0.889502 5.81617 0.745641 6.60997C0.60178 7.40378 0.639874 8.21987 0.857054 8.99682C1.07423 9.77377 1.46484 10.4913 1.99947 11.0955"
                      stroke="white"
                      strokeWidth="1.6"
                      stroke-linecap="round"
                      strokeLinejoin="round"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_1149_4625">
                      <rect
                        width="16"
                        height="16"
                        fill="white"
                        transform="translate(0 0.235474)"
                      />
                    </clipPath>
                  </defs>
                </svg>
              </span>
            </Button>
          </>
        )}
      </Modal.Footer>
    </Modal>
  )
}

export default PurchaseSupportModal
