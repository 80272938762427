import { Action, ThunkAction, configureStore } from '@reduxjs/toolkit';
import discussionsReducer from './slices/discussion-slice';
import productsReducer from './slices/product-slice';
import supportsReducer from './slices/support-slice';
import sessionReducer from './slices/token-slice';
import usersReducer from './slices/user-slice';

export const store = configureStore({
  reducer: {
    users: usersReducer,
    session: sessionReducer,
    products: productsReducer,
    supports: supportsReducer,
    discussions: discussionsReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
