import HeaderConnected from 'components/Header/HeaderConnected'
import TokenExpired from 'components/Modal/TokenExpired/token-expired'
import 'moment/locale/fr'
import 'moment/locale/pt'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Navigate, Outlet, useParams } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import { selectUserInfo } from 'stores/slices/user-slice'
import { RootState } from 'stores/store'
const ProtectedRoute = () => {
  const userInfo = useSelector(selectUserInfo)
  const { i18n } = useTranslation()
  const { lang } = useParams()
  const isTokenExpired = useSelector(
    (state: RootState) => state.session.isTokenExpired,
  )
  useEffect(() => {
    if (lang && ['fr', 'pt'].includes(lang)) {
      i18n.changeLanguage(lang)
    } else {
      console.log(`Langue non prise en charge: ${lang}`)
      i18n.changeLanguage('fr')
    }
  }, [i18n, lang])
  return userInfo ? (
    <>
      <TokenExpired isVisible={isTokenExpired} />
      <ToastContainer
        position="top-right"
        autoClose={3500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <HeaderConnected />
      <Outlet />
    </>
  ) : (
    <Navigate to="/fr" replace />
  )
}

export default ProtectedRoute
