import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

interface CKEditorComponentProps {
  initialContent: string
  onChange: (content: string) => void
}

const CKEditorComponent: React.FC<CKEditorComponentProps> = ({
  initialContent,
  onChange,
}) => {
  const handleEditorChange = (event: any): any => {
    onChange(event)
  }
  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [
        { list: 'ordered' },
        { list: 'bullet' },
        { indent: '-1' },
        { indent: '+1' },
      ],
      ['link'], // 'image'
      ['clean'],
    ],
    clipboard: {
      matchVisual: false,
    },
  }

  const formats = [
    'header',
    'font',
    'size',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    'image',
  ]

  return (
    <>
      <ReactQuill
        theme="snow"
        modules={modules}
        formats={formats}
        value={initialContent}
        onChange={handleEditorChange}
      />
    </>
  )
}

export default CKEditorComponent
