import axios from 'axios';
const API_URL = process.env.REACT_APP_REST_API_LOCATION;

const responseService = {
  getResponse: async (slug: any, page?: number) => {
    try {
      const response = await axios.get(
        `${API_URL}/responses-discussions/${slug}?&page=${page || 1}`
      );
      return response.data;
    } catch (error) {
      console.error('Error during order confirmation request:', error);
      throw error;
    }
  },

  delete: async (id: string) => {
    try {
      const response = await axios.delete(`${API_URL}/remove-response/${id}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  update: async (
    id: string,
    locale: any,
    response_texte: any,
    idDiscussion: any,
    file?: any,
    idFileToDelete?: any
  ) => {
    try {
      const formData = new FormData();

      if (file && file.length > 0) {
        file.map((res: any, index: number) => {
          formData.append(`file.reponse${index}`, res);
        });

        formData.append('fileNumber', file.length);
      }

      if (idFileToDelete && idFileToDelete.length > 0) {
        formData.append('fileDelete', idFileToDelete);
      }

      formData.append('response_texte', response_texte);
      formData.append('locale', locale);
      formData.append('idDiscussion', idDiscussion);

      const response = await axios.put(
        `${API_URL}/update-response/${id}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      return response.data;
    } catch (e) {
      console.log(e);
    }
  },

  saveResponse: async (
    locale: any,
    response_texte: any,
    idDiscussion: any,
    idUser: any,
    file?: any
  ) => {
    try {
      const formData = new FormData();

      if (file && file.length > 0) {
        file.map((res: any, index: number) => {
          formData.append(`file.reponse${index}`, res);
        });

        formData.append('fileNumber', file.length);
      }

      formData.append('response_texte', response_texte);
      formData.append('locale', locale);
      formData.append('idDiscussion', idDiscussion);
      formData.append('idUser', idUser);

      // Effectuez la requête avec axios
      const response = await axios.post(`${API_URL}/save-response`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      // Retournez les données de réponse
      return response.data;
    } catch (error) {
      console.error('Error during order confirmation request:', error);
      throw error;
    }
  },
};

export default responseService;
