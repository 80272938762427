import axios from 'axios';
const API_URL = process.env.REACT_APP_REST_API_LOCATION;

const authService = {
  login: async (username: string, password: string) => {
    try {
      const response = await axios.post(`${API_URL}/users-permissions/login`, {
        identifier: username,
        password,
      });
      return response.data;
    } catch (error) {
      console.error('Error during login request:', error);
      throw error;
    }
  },

  register: async (userData: any, code: string | null) => {
    try {
      const formData = new FormData();

      formData.append('email', userData.email);
      formData.append('username', userData.email);
      formData.append('firstname', userData.firstName);
      formData.append('name', userData.name);
      formData.append('password', userData.password);
      formData.append('role', userData.roleID);
      formData.append('departmentId', userData.district);
      formData.append('country', userData.country);
      formData.append('phone_number', userData.phone);

      if (code) {
        formData.append('code', code);
      }

      if (userData.PHOTO) {
        formData.append('file.photo', userData.PHOTO);
      }

      if (userData.SIRET) {
        formData.append('file.siret_file', userData.SIRET);
      }

      if (userData.identityProof) {
        formData.append('file.IdFile', userData.identityProof);
      }

      if (userData.insuranceCertificate) {
        formData.append('file.insurance_file', userData.insuranceCertificate);
      }

      return axios.post(`${API_URL}/users-permissions/register`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    } catch (error) {
      console.error('Error during register request:', error);
      throw error;
    }
  },

  getCoptation: async (userId: any) => {
    try {
      const response = await axios.get(
        `${API_URL}/users-permissions/coptations/${userId}`
      );
      return response.data;
    } catch (error) {
      console.error('Error during getUserInfo request:', error);
      throw error;
    }
  },

  getMeInfo: async () => {
    try {
      const response = await axios.get(`${API_URL}/users-permissions/me`);
      return response.data;
    } catch (error) {
      console.error('Error during getUserInfo request:', error);
      throw error;
    }
  },

  removeFile: async (id: number) => {
    try {
      const response = await axios.delete(`${API_URL}/upload/files/${id}`);
      return response.data;
    } catch (error) {
      console.error('Error during getUserInfo request:', error);
      throw error;
    }
  },

  editUserInfo: async (userData: any, id: number) => {
    try {
      const formData = new FormData();
      formData.append('from', 'true');
      formData.append('email', userData.email);
      formData.append('username', userData.email);
      formData.append('firstname', userData.firstName);
      formData.append('name', userData.name);
      formData.append('phone_number', userData.phone);

      if (userData.password) {
        formData.append('password', userData.password);
      }

      if (userData.PHOTO) {
        formData.append('file.photo', userData.PHOTO);
      }

      if (userData.SIRET) {
        formData.append('file.siret_file', userData.SIRET);
      }

      if (userData.identityProof) {
        formData.append('file.IdFile', userData.identityProof);
      }

      if (userData.insuranceCertificate) {
        formData.append('file.insurance_file', userData.insuranceCertificate);
      }

      return axios.put(`${API_URL}/users-permissions/update/${id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    } catch (error) {
      console.error('Error during register request:', error);
      throw error;
    }
  },

  viewProfilInfo: async (userId: string) => {
    try {
      const response = await axios.get(
        `${API_URL}/users-permissions/get-user/${userId}`
      );
      return response.data;
    } catch (error) {
      console.error('Error during getUserInfo request:', error);
      throw error;
    }
  },

  child: async (userId: string) => {
    try {
      const response = await axios.get(
        `${API_URL}/users-permissions/child/${userId}`
      );
      return response.data;
    } catch (error) {
      console.error('Error during getUserInfo request:', error);
      throw error;
    }
  },
};

export default authService;
