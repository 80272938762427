/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable jsx-a11y/anchor-is-valid */
import CKEditorComponent from 'components/CKEditorComponent/CKEditorComponent'
import LoaderGameiro from 'components/LoaderGameiro/Loader'
import ModalComponent from 'components/Modal/Delete/Discussion.Delete'
import ModalDeleteDiscussion from 'components/Modal/DeleteDiscuss/Discussion.Delete'
import DiscussionCreateModale from 'components/Modal/Discussion/DiscussionCreate'
import { saveAs } from 'file-saver'
import parse from 'html-react-parser'
import moment from 'moment'
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ReactQuill from 'react-quill'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import discussionService from 'services/discussion.service'
import responseService from 'services/response.service'
import { selectUserInfo } from 'stores/slices/user-slice'
import './style.scss'

interface ApiResponseItem {
  id: number
  createdAt: string
  author: {
    id: number
    name: string
    firstname: string
  }
  createdByme: boolean
  isMyAnswer: boolean
  isMember: boolean
  response_texte: string
  file_response: any[]
  discussionInfo: {
    id: number
    name: string
    createdAt: string
    descriptions: string
    status: string
    isPin: boolean
    nbResponse: number
    author: {
      name: string
      firstname: string
      photo: string
    }
  }
  isNew: boolean
}
interface FileWithObjectURL {
  file: File
  objectURL: string
}

const DetailsDiscussion: React.FC = () => {
  const { t, i18n } = useTranslation()
  const [initialContent, setContent] = useState<string>('')
  const [initialContentReply, setContentReply] = useState<string>('')
  const [idReply, setIdReply] = useState<string>('')
  const { slug, lang } = useParams()
  const navigate = useNavigate()
  const [apiResponse, setApiResponse] = useState<any>([])
  const [banResponse, setBanResponse] = useState<any>([])
  const [loading, setLoading] = useState(true)
  const [loadingEditReponse, setLoadingEditReponse] = useState(false)
  const [loadingResponse, setLoadingResponse] = useState(false)
  const [loadingReply, setLoadingReply] = useState(false)
  const [isNewData, setIsnewData] = useState(false)
  const [currentPage, serCurrentPage] = useState(1)
  const [pageCount, setPageCount] = useState(1)
  const [idToDelete, setIdToDelete] = useState('')
  const [isAnswer, setIsMyAnswer] = useState(false)
  const handleEditorChange = (newContent: string) => {
    setContent(newContent)
  }
  const [isSubmitted, setSubmitted] = useState(false)
  const [isSubmittedEditReponse, setSubmittedEditReponse] = useState(false)
  const [isSubmittedReply, setSumittedReply] = useState(false)
  const [idFileToDelete, setStateArray] = useState<any[]>([])

  const [initialEditContent, setEditContent] = useState<string>('')
  const [modalShow, setModalShow] = useState(false)
  const [modalShowTwo, setModalShowTwo] = useState(false)

  const [modalShowThree, setShowModalThree] = useState(false)
  const [idEdit, setIdEdit] = useState('')
  const handleModalOpen = (id: number | string, isMyAnswer: boolean) => {
    setIdToDelete(id.toString())
    setModalShow(true)
    setIsMyAnswer(isMyAnswer)
  }
  const [selectedFiles, setSelectedFiles] = useState<File[]>([])
  const [selectedFilesEditReply, setSelectedFileEditReply] = useState<File[]>(
    [],
  )

  const handleCloselOpen = (id?: number | string) => {
    setIdToDelete('')
    setModalShow(false)
    setIsMyAnswer(false)
    if (id) {
      setIsnewData(true)
    }
  }
  const handleModalCloseEditDiscuss = (id?: number | string) => {
    setShowModalThree(false)

    if (id) {
      setIsnewData(true)
    }
  }

  const userInfo = useSelector(selectUserInfo)

  const [pinned, setPinned] = useState(false)

  const [locked, setLocked] = useState(false)

  const download = async (urlData: string) => {
    const url = urlData
    const fileName = url ? url.substring(url?.lastIndexOf('/') + 1) : 'file'
    try {
      saveAs(url || '', fileName)
    } catch (error) {
      console.error('Erreur lors du téléchargement du fichier :', error)
    }
  }
  const truncateFileName = (fileName: string, maxLength: number): string => {
    if (fileName.length <= maxLength) {
      return fileName
    } else {
      return fileName.slice(0, maxLength) + '...'
    }
  }

  const handleScroll = useCallback(async () => {
    const isBottom =
      window.innerHeight + document.documentElement.scrollTop ===
      document.documentElement.offsetHeight

    if (isBottom) {
      if (pageCount > currentPage) {
        try {
          const response = await responseService.getResponse(
            slug,
            currentPage + 1,
          )
          setApiResponse(response?.discussionInfo)
          const newBanItem =
            response?.data.length > 0
              ? response?.data
                  .slice()
                  .sort((a: any, b: any) => a.id - b.id)
                  .map((res: any) => ({
                    ...res,
                    key: Math.random().toString(36).substr(2, 10),
                  }))
              : []

          setBanResponse((prevBanResponse: any) => [
            ...prevBanResponse,
            ...newBanItem,
          ])
          setPageCount(response?.pagination?.pageCount)
          serCurrentPage(response?.pagination?.page)
          setPinned(response?.discussionInfo.isPin)
          if (response.discussionInfo.status === 'closed') {
            setLocked(true)
          }
          if (response.data) {
            setLoading(false)
          }
        } catch (error) {
          setLoading(false)
          console.error("Erreur lors de l'appel API :", error)
        }
      }
    }
  }, [currentPage, pageCount, slug])

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [handleScroll])

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newFiles = event.target.files || []
    const totalFiles = [...selectedFiles, ...Array.from(newFiles)]

    if (totalFiles.length > 5) {
      toast.warning("Vous ne pouvez sélectionner que jusqu'à 5 fichiers.")
    } else {
      const acceptedFiles = totalFiles.filter((file) => {
        const acceptedTypes = ['image/jpeg', 'image/png', 'application/pdf']
        const isTypeAccepted = acceptedTypes.includes(file.type)

        const isSizeAccepted = file.size <= 5 * 1024 * 1024

        if (!isSizeAccepted) {
          toast.warning(
            `La taille du fichier ${file.name} dépasse la limite de 5 Mo.`,
          )
        }

        return isTypeAccepted && isSizeAccepted
      })

      console.log('Fichiers sélectionnés :', acceptedFiles)
      setSelectedFiles(acceptedFiles)
    }
  }

  const handleFileChangeEditReply = (event: ChangeEvent<HTMLInputElement>) => {
    const newFiles = event.target.files || []

    const totalFiles = [...selectedFilesEditReply, ...Array.from(newFiles)]

    if (totalFiles.length > 5) {
      toast.warning("Vous ne pouvez sélectionner que jusqu'à 5 fichiers.")
    } else {
      const acceptedFiles = totalFiles.filter((file) => {
        const acceptedTypes = ['image/jpeg', 'image/png', 'application/pdf']
        const isTypeAccepted = acceptedTypes.includes(file.type)

        const isSizeAccepted = file.size <= 5 * 1024 * 1024

        if (!isSizeAccepted) {
          toast.warning(
            `La taille du fichier ${file.name} dépasse la limite de 5 Mo.`,
          )
        }

        return isTypeAccepted && isSizeAccepted
      })

      console.log('Fichiers sélectionnés :', acceptedFiles)
      setSelectedFileEditReply(acceptedFiles)
    }
  }

  const useObjectURLForImage = (file: any): FileWithObjectURL | null => {
    if (file && file.type && file.type.startsWith('image/')) {
      return {
        file,
        objectURL: !file?.mime ? URL.createObjectURL(file) : file.url,
      }
    }

    return null
  }

  const showResponse = () => {
    if (
      apiResponse?.status === 'open' ||
      (apiResponse?.status === 'closed' && userInfo?.user.role.id === 3)
    ) {
      return true
    }
    return false
  }

  const handleDeleteFile = (index: number) => {
    // Supprimez le fichier à l'index spécifié
    const updatedFiles = [...selectedFiles]
    updatedFiles.splice(index, 1)
    setSelectedFiles(updatedFiles)
  }

  const handleDeleteFileEdit = (index: number, fileId?: number) => {
    // Supprimez le fichier à l'index spécifié
    const updatedFiles = [...selectedFilesEditReply]

    updatedFiles.splice(index, 1)
    setSelectedFileEditReply(updatedFiles)
    if (fileId) {
      setStateArray((prevArray) => [...prevArray, fileId])
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      try {
        const response = await responseService.getResponse(slug)

        setApiResponse(response?.discussionInfo)
        setBanResponse(
          response?.data.length > 0
            ? response?.data
                .slice()
                .sort((a: any, b: any) => a.id - b.id)
                .map((res: any) => {
                  return {
                    ...res,
                    key: Math.random().toString(36).substr(2, 10),
                  }
                })
            : [],
        )

        setPageCount(response?.pagination?.pageCount)
        setPinned(response?.discussionInfo.isPin)
        if (response.discussionInfo.status === 'closed') {
          setLocked(true)
        }
        if (response.data) {
          await discussionService.setActivity(slug || '')
          setLoading(false)
        }
      } catch (error) {
        setLoading(false)
        console.error("Erreur lors de l'appel API :", error)
      }
    }

    fetchData()

    return () => {}
  }, [slug, lang])

  const [editWidgetFormState, setEditWidgetFormState] = useState({
    // initial state
    description: '',
  })

  const handleQuillReply = (value: any, index: number) => {
    setContentReply(value)
  }

  const handleQuillEdit = (value: any, index: number) => {
    setEditWidgetFormState((prev: any) => {
      const updatedDescriptions = [...prev.description]
      updatedDescriptions[index] = value
      setEditContent(value)
      return {
        ...prev,
        description: updatedDescriptions,
      }
    })
  }

  const handleReply = async () => {
    setSumittedReply(true)
    if (
      initialContentReply.trim() !== '' &&
      initialContentReply.trim() !== '<p><br></p>'
    ) {
      try {
        setLoadingReply(true)
        await responseService.saveResponse(
          lang || 'fr',
          initialContentReply,
          slug,
          userInfo?.user.id,
          selectedFilesEditReply,
        )
        setIdEdit('')
        setIdReply('')
        setEditContent('')
        setContentReply('')
        setIsnewData(true)
        setSelectedFileEditReply([])
        setSumittedReply(false)
        setLoadingReply(false)
        handleScrollDown()
        toast.success(t('DETAILS.REPLY.SEND_REPLY'))
      } catch (error) {
        setSumittedReply(false)
        setLoadingReply(false)
        console.error('Error during saveResponse:', error)
      }
    }
  }
  const handleEditResponse = async () => {
    const files = selectedFilesEditReply.filter((res: any) => {
      return !res.id
    })
    setSubmittedEditReponse(true)
    if (initialEditContent.trim() !== '<p><br></p>') {
      try {
        setLoadingEditReponse(true)
        const data = await responseService.update(
          idEdit,
          lang,
          initialEditContent,
          slug,
          files,
          idFileToDelete,
        )
        if (data) {
          setIdEdit('')
          setSubmittedEditReponse(false)
          setIsnewData(true)
          setLoadingEditReponse(false)
          setSelectedFileEditReply([])
          setStateArray([])
        }
      } catch (e) {
        setLoadingEditReponse(false)
        console.log('error', e)
      }
    } else {
      setIdEdit('')
      setSubmittedEditReponse(false)
    }
  }

  useEffect(() => {
    if (isNewData) {
      const fetchData = async () => {
        try {
          const response = await responseService.getResponse(slug)
          setApiResponse(response?.discussionInfo)
          setBanResponse(
            response?.data.length > 0
              ? response?.data.slice().sort((a: any, b: any) => a.id - b.id)
              : [],
          )
          if (response.data) {
            setLoading(false)
          }
        } catch (error) {
          setLoading(false)
        }
      }

      fetchData()
      setIsnewData(false)
      return () => {}
    }
  }, [slug, lang, isNewData])

  const handleScrollDown = () => {
    window.scrollTo({
      top: document.body.scrollHeight,
      behavior: 'smooth',
    })
  }

  const back = () => {
    navigate(`/${lang}/discussion`)
  }
  const requiredFile = () => {
    if (
      (isSubmitted && initialContent === '<p><br></p>') ||
      (isSubmitted && !initialContent.trim())
    ) {
      return true
    }
  }

  const handleSaveResponse = async () => {
    setSubmitted(true)
    if (
      initialContent.trim() !== '' &&
      initialContent.trim() !== '<p><br></p>'
    ) {
      try {
        setLoadingResponse(true)
        await responseService.saveResponse(
          lang || 'fr',
          initialContent,
          slug,
          userInfo?.user.id,
          selectedFiles,
        )
        setIsnewData(true)
        setSubmitted(false)
        setContent('')
        setLoadingResponse(false)
        setSelectedFiles([])
        toast.success(t('DETAILS.REPLY.SAVE_REPLY'))
      } catch (error) {
        setSubmitted(false)
        setLoadingResponse(false)
        console.error('Error during saveResponse:', error)
      }
    }
  }

  return (
    <>
      <div className="banner banner--w-artwork">
        <div className="container container--small">
          <div className="banner__inner">
            <div className="btn-back banner__back color-white" onClick={back}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M12 19L5 12M5 12L12 5M5 12H19"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <span className="btn-back__text">{t('DETAILS.BACK')}</span>
            </div>
            {apiResponse.isPin && (
              <div className="pin-content">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="14"
                  viewBox="0 0 16 14"
                  fill="none"
                >
                  <path
                    d="M7.18555 0.282035C6.78709 -0.043122 6.20257 0.0162192 5.87754 0.414826L1.17646 6.17999C0.851426 6.57859 0.910745 7.16333 1.3092 7.48848C1.70766 7.81364 2.29218 7.7543 2.61721 7.35569L3.15894 6.69135L6.28584 9.67057C6.05988 10.8626 6.27698 12.1275 6.9578 13.2006L7.00697 13.2782C7.16699 13.5326 7.43908 13.6909 7.73611 13.7082C8.03314 13.7256 8.32382 13.599 8.51297 13.367L14.3893 6.16057C14.5785 5.92861 14.6444 5.6224 14.5674 5.33072C14.4903 5.03904 14.2788 4.80644 13.9998 4.70256L13.9139 4.66997C12.7263 4.21838 11.4441 4.25973 10.3222 4.72064L6.77657 2.25488L7.31829 1.59053C7.64333 1.19193 7.58401 0.607191 7.18555 0.282035ZM12.7592 9.631L11.5839 11.0723L13.745 12.8359C14.1435 13.161 14.728 13.1017 15.053 12.7031C15.3781 12.3045 15.3187 11.7197 14.9203 11.3946L12.7592 9.631Z"
                    fill="#F2F4F7"
                  />
                </svg>

                <p
                  style={{
                    fontStyle: 'italic',
                    marginLeft: '0.5rem',
                  }}
                >
                  {t('DETAILS.PIN_DISCUSSION')}
                </p>
              </div>
            )}
            <h1 className="heading-2 color-white">{apiResponse?.name}</h1>
            <p className="banner__desc paragraph">
              {apiResponse?.descriptions
                ? parse(apiResponse?.descriptions)
                : ''}
            </p>
            {!loading && (
              <div className="message-info d-sm-flex">
                <div className="d-flex align-items-center">
                  <div className="message-info__icon">
                    <img
                      src={apiResponse?.author?.photo}
                      alt="Header Imagery"
                    />
                  </div>
                  <p className="message-info__name">
                    {' '}
                    {apiResponse?.author?.name} {apiResponse?.author?.firstname}
                  </p>
                </div>
                <ul className="list-notif">
                  <li className="list-notif__item">
                    Posté{' '}
                    {moment(apiResponse?.createdAt)
                      .locale(lang || 'fr')
                      .fromNow()}
                  </li>
                  <li className="list-notif__item">
                    {apiResponse?.nbResponse} réponse
                    {apiResponse?.nbResponse > 1 ? 's' : ''}.
                  </li>
                </ul>
              </div>
            )}
          </div>
        </div>
      </div>

      {loading && <LoaderGameiro />}
      <div className="container container--small my-3">
        <div className="row justify-content-lg-between">
          <div className="col-lg-8">
            <div className="discussion-list">
              {banResponse.filter((res: any) => {
                return !res.isNew
              }).length > 0 &&
                banResponse
                  .filter((res: any) => {
                    return !res.isNew
                  })
                  .map((item: any, index: any) => (
                    <div
                      className="discussion-item"
                      key={item?.response_texte + item.key}
                    >
                      <div className="discussion-item__header d-flex align-items-center justify-content-between">
                        <div className="profile-status d-flex align-items-center">
                          <div className="profile-status__img">
                            <img alt="" src={item?.author?.photo} />
                          </div>
                          <div className="profile-status__content">
                            <a className="profile-status__name" href="#">
                              {item?.author?.name} {item?.author?.firstname}
                            </a>
                            <p className="profile-status__date date">
                              {moment(item?.createdAt)
                                .locale(lang || 'fr')
                                .fromNow()}
                            </p>
                          </div>
                        </div>
                        <div className="discussion-item__btn">
                          {apiResponse.status === 'open' && (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              onClick={() => {
                                setIdReply(item.id.toString())

                                setIdEdit('')
                                setEditContent('')
                                setSelectedFileEditReply([])
                                setStateArray([])
                                setContentReply('')
                              }}
                            >
                              <path
                                d="M9 17L4 12M4 12L9 7M4 12H16C17.0609 12 18.0783 12.4214 18.8284 13.1716C19.5786 13.9217 20 14.9391 20 16V18"
                                stroke="#004519"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          )}
                          {item?.author?.role === 3 &&
                            userInfo?.user.role.id === 4 && (
                              <button className="btn btn--primary btn--lock">
                                {t('DETAILS.MODERATOR_BUTTON')}
                              </button>
                            )}
                          {item.isMyAnswer && userInfo?.user.role.id === 4 && (
                            <>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                style={{
                                  marginLeft: '8px',
                                }}
                                viewBox="0 0 24 24"
                                fill="none"
                                onClick={() => {
                                  setIdEdit(item.id.toString())

                                  if (item.file_response.length > 0) {
                                    setSelectedFileEditReply(item.file_response)
                                  }
                                }}
                              >
                                <path
                                  d="M4 13.5V4C4 3.46957 4.21071 2.96086 4.58579 2.58579C4.96086 2.21071 5.46957 2 6 2H14.5L20 7.5V20C20 20.5304 19.7893 21.0391 19.4142 21.4142C19.0391 21.7893 18.5304 22 18 22H12.5M14 2V8H20M10.42 12.61C10.615 12.415 10.8465 12.2603 11.1013 12.1547C11.3561 12.0492 11.6292 11.9949 11.905 11.9949C12.1808 11.9949 12.4539 12.0492 12.7087 12.1547C12.9635 12.2603 13.195 12.415 13.39 12.61C13.585 12.805 13.7397 13.0365 13.8452 13.2913C13.9508 13.5461 14.0051 13.8192 14.0051 14.095C14.0051 14.3708 13.9508 14.6439 13.8452 14.8987C13.7397 15.1535 13.585 15.385 13.39 15.58L7.95 21L4 22L4.99 18.05L10.42 12.61Z"
                                  stroke="#004519"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                style={{
                                  marginLeft: '8px',
                                }}
                                viewBox="0 0 24 24"
                                fill="none"
                                onClick={() => {
                                  handleModalOpen(
                                    item.id,
                                    item?.isMyAnswer,
                                  ) as any
                                }}
                              >
                                <path
                                  d="M3 6H21M19 6V20C19 21 18 22 17 22H7C6 22 5 21 5 20V6M8 6V4C8 3 9 2 10 2H14C15 2 16 3 16 4V6M10 11V17M14 11V17"
                                  stroke="#004519"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                            </>
                          )}
                          {userInfo?.user.role.id === 3 && (
                            <>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                style={{
                                  marginLeft: '8px',
                                }}
                                viewBox="0 0 24 24"
                                fill="none"
                                onClick={() => {
                                  setIdEdit(item.id.toString())

                                  setContentReply('')
                                  if (item.file_response.length > 0) {
                                    setSelectedFileEditReply(item.file_response)
                                  }
                                }}
                              >
                                <path
                                  d="M4 13.5V4C4 3.46957 4.21071 2.96086 4.58579 2.58579C4.96086 2.21071 5.46957 2 6 2H14.5L20 7.5V20C20 20.5304 19.7893 21.0391 19.4142 21.4142C19.0391 21.7893 18.5304 22 18 22H12.5M14 2V8H20M10.42 12.61C10.615 12.415 10.8465 12.2603 11.1013 12.1547C11.3561 12.0492 11.6292 11.9949 11.905 11.9949C12.1808 11.9949 12.4539 12.0492 12.7087 12.1547C12.9635 12.2603 13.195 12.415 13.39 12.61C13.585 12.805 13.7397 13.0365 13.8452 13.2913C13.9508 13.5461 14.0051 13.8192 14.0051 14.095C14.0051 14.3708 13.9508 14.6439 13.8452 14.8987C13.7397 15.1535 13.585 15.385 13.39 15.58L7.95 21L4 22L4.99 18.05L10.42 12.61Z"
                                  stroke="#004519"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                style={{
                                  marginLeft: '8px',
                                }}
                                viewBox="0 0 24 24"
                                fill="none"
                                onClick={() => {
                                  handleModalOpen(
                                    item.id,
                                    item?.isMyAnswer,
                                  ) as any
                                }}
                              >
                                <path
                                  d="M3 6H21M19 6V20C19 21 18 22 17 22H7C6 22 5 21 5 20V6M8 6V4C8 3 9 2 10 2H14C15 2 16 3 16 4V6M10 11V17M14 11V17"
                                  stroke="#004519"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                            </>
                          )}
                        </div>
                      </div>
                      <div className="discussion-item__content">
                        {idEdit !== item.id.toString() && (
                          <>
                            <p>
                              {item?.response_texte
                                ? parse(item?.response_texte)
                                : ''}
                            </p>
                            {item.file_response &&
                              item.file_response.length > 0 && (
                                <div className="discussion-item__media">
                                  <div className="row">
                                    {item.file_response &&
                                      item.file_response.length > 0 && (
                                        <>
                                          {item.file_response
                                            .filter((file: any) =>
                                              file.mime.includes('image'),
                                            )
                                            .map((image: any, index: any) => (
                                              <div
                                                key={index}
                                                className="col-6"
                                              >
                                                <div className="discussion-item__image">
                                                  <img
                                                    src={image.url}
                                                    height="209"
                                                    width={343}
                                                    alt={`Image ${index + 1}`}
                                                    onClick={() =>
                                                      window.open(
                                                        image.url,
                                                        '_blank',
                                                      )
                                                    }
                                                    style={{
                                                      cursor: 'pointer',
                                                    }}
                                                  />
                                                </div>
                                              </div>
                                            ))}

                                          {item.file_response
                                            .filter(
                                              (file: any) =>
                                                !file.mime.includes('image'),
                                            )
                                            .map((file: any, index: any) => (
                                              <div
                                                key={index}
                                                className="col-6"
                                              >
                                                <div className="file-upload file-upload--pdf">
                                                  <span
                                                    className="file-upload__icon--pdf"
                                                    onClick={(e) => {
                                                      e.stopPropagation()
                                                      download(file.url)
                                                    }}
                                                  ></span>
                                                  <span
                                                    className="name"
                                                    onClick={(e) => {
                                                      e.stopPropagation()
                                                      download(file.url)
                                                    }}
                                                  >
                                                    {file.name}
                                                  </span>{' '}
                                                </div>
                                              </div>
                                            ))}
                                        </>
                                      )}
                                  </div>
                                </div>
                              )}
                          </>
                        )}

                        {idEdit === item.id.toString() && (
                          <>
                            <div className="quill-container">
                              <ReactQuill
                                defaultValue={item?.response_texte}
                                onChange={(value) =>
                                  handleQuillEdit(value, index)
                                }
                                onFocus={() => {
                                  handleQuillEdit(item?.response_texte, index)
                                }}
                                value={
                                  initialEditContent || item?.response_texte
                                }
                              />
                              <div className="add-note">
                                <label htmlFor="fileInput">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="19"
                                    height="18"
                                    viewBox="0 0 19 18"
                                    fill="none"
                                  >
                                    <path
                                      d="M10.8335 1.5V6H15.3335M9.3335 13.5V9M7.0835 11.25H11.5835M11.2085 1.5H4.8335C4.43567 1.5 4.05414 1.65804 3.77284 1.93934C3.49153 2.22064 3.3335 2.60218 3.3335 3V15C3.3335 15.3978 3.49153 15.7794 3.77284 16.0607C4.05414 16.342 4.43567 16.5 4.8335 16.5H13.8335C14.2313 16.5 14.6129 16.342 14.8942 16.0607C15.1755 15.7794 15.3335 15.3978 15.3335 15V5.625L11.2085 1.5Z"
                                      strokeWidth="1.5"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                </label>
                                <input
                                  type="file"
                                  id="fileInput"
                                  style={{ display: 'none' }}
                                  onChange={handleFileChangeEditReply}
                                  accept=".jpg, .jpeg, .png, .pdf"
                                  multiple
                                />
                              </div>
                              <div className="row w-100 block--files">
                                {selectedFilesEditReply.map(
                                  (file: any, index: any) => (
                                    <div
                                      key={index}
                                      className="file-wrapper col-6"
                                    >
                                      <div className="file-upload file-upload--pdf">
                                        {file.type?.match(
                                          /image\/(jpg|jpeg|png)/,
                                        ) ||
                                        file.mime?.match(
                                          /image\/(jpg|jpeg|png)/,
                                        ) ? (
                                          <>
                                            <div className="profile-status__img">
                                              <img
                                                alt=""
                                                src={
                                                  file.mime
                                                    ? file.url
                                                    : useObjectURLForImage(file)
                                                        ?.objectURL
                                                }
                                              />{' '}
                                            </div>
                                            <span className="name">
                                              {' '}
                                              {truncateFileName(file?.name, 15)}
                                            </span>{' '}
                                          </>
                                        ) : (
                                          <>
                                            {' '}
                                            <span className="file-upload__icon--pdf"></span>
                                            <span className="name">
                                              {truncateFileName(file?.name, 15)}
                                            </span>{' '}
                                          </>
                                        )}
                                        <span
                                          className="delete-icon"
                                          onClick={() =>
                                            handleDeleteFileEdit(
                                              index,
                                              file?.id,
                                            )
                                          }
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                          >
                                            <path
                                              d="M15 9L9 15M9 9L15 15M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
                                              stroke="#98A2B3"
                                              strokeWidth="2"
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                            />
                                          </svg>
                                        </span>
                                      </div>
                                    </div>
                                  ),
                                )}
                              </div>
                              {isSubmittedEditReponse &&
                                ((editWidgetFormState.description[index] ===
                                  '<p><br></p>' && (
                                  <div className="error-message">
                                    {t('DETAILS.REQUIRED_FIELD')}
                                  </div>
                                )) ||
                                  (initialEditContent === '<p><br></p>' && (
                                    <div className="error-message">
                                      {t('DETAILS.REQUIRED_FIELD')}
                                    </div>
                                  )))}

                              <div className="d-flex justify-content-between">
                                <div className="file-wrapper d-flex"></div>
                                <div className="quill-buttons">
                                  <button
                                    className="btn btn--secondary btn--lock"
                                    onClick={() => {
                                      setIdEdit('')
                                      setEditContent('')
                                      setSelectedFileEditReply([])
                                      setStateArray([])
                                      setContentReply('')
                                    }}
                                  >
                                    {t('DETAILS.BUTTON.CANCEL')}
                                  </button>
                                  <button
                                    className="btn btn--primary btn--lock replying"
                                    onClick={() => {
                                      handleEditResponse()
                                    }}
                                  >
                                    {loadingEditReponse
                                      ? t('DETAILS.BUTTON.LOAD_UPDATE')
                                      : t('DETAILS.BUTTON.UPDATE')}
                                  </button>
                                </div>
                              </div>
                            </div>
                          </>
                        )}

                        {idReply === item.id.toString() && (
                          <>
                            <div className="quill-container">
                              <ReactQuill
                                defaultValue={`<p>@${item?.author?.name} </p`}
                                onChange={(value) =>
                                  handleQuillReply(value, index)
                                }
                                value={
                                  initialContentReply ||
                                  `<p>@${item?.author?.name} </p>`
                                }
                              />

                              <div className="add-note">
                                <label htmlFor="fileInput">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="19"
                                    height="18"
                                    viewBox="0 0 19 18"
                                    fill="none"
                                  >
                                    <path
                                      d="M10.8335 1.5V6H15.3335M9.3335 13.5V9M7.0835 11.25H11.5835M11.2085 1.5H4.8335C4.43567 1.5 4.05414 1.65804 3.77284 1.93934C3.49153 2.22064 3.3335 2.60218 3.3335 3V15C3.3335 15.3978 3.49153 15.7794 3.77284 16.0607C4.05414 16.342 4.43567 16.5 4.8335 16.5H13.8335C14.2313 16.5 14.6129 16.342 14.8942 16.0607C15.1755 15.7794 15.3335 15.3978 15.3335 15V5.625L11.2085 1.5Z"
                                      strokeWidth="1.5"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                </label>
                                <input
                                  type="file"
                                  id="fileInput"
                                  style={{ display: 'none' }}
                                  onChange={handleFileChangeEditReply}
                                  accept=".jpg, .jpeg, .png, .pdf"
                                  multiple
                                />
                              </div>
                              <div className="row w-100 block--files">
                                {selectedFilesEditReply.map((file, index) => (
                                  <div
                                    key={index}
                                    className="file-wrapper col-6"
                                  >
                                    <div className="file-upload file-upload--pdf">
                                      {file.type?.match(
                                        /image\/(jpg|jpeg|png)/,
                                      ) ? (
                                        <>
                                          <div className="profile-status__img">
                                            <img
                                              alt=""
                                              src={
                                                useObjectURLForImage(file)
                                                  ?.objectURL
                                              }
                                            />{' '}
                                          </div>
                                          <span className="name">
                                            {' '}
                                            {truncateFileName(file?.name, 15)}
                                          </span>{' '}
                                        </>
                                      ) : (
                                        <>
                                          {' '}
                                          <span className="file-upload__icon--pdf"></span>
                                          <span className="name">
                                            {truncateFileName(file?.name, 15)}{' '}
                                            XX
                                          </span>{' '}
                                        </>
                                      )}
                                      <span
                                        className="delete-icon"
                                        onClick={() =>
                                          handleDeleteFileEdit(index)
                                        }
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="24"
                                          height="24"
                                          viewBox="0 0 24 24"
                                          fill="none"
                                        >
                                          <path
                                            d="M15 9L9 15M9 9L15 15M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
                                            stroke="#98A2B3"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          />
                                        </svg>
                                      </span>
                                    </div>
                                  </div>
                                ))}
                              </div>
                              {isSubmittedReply &&
                                initialContentReply === '<p><br></p>' && (
                                  <>
                                    {' '}
                                    <div className="error-message">
                                      {t('DETAILS.REQUIRED_FIELD')}
                                    </div>
                                  </>
                                )}

                              <div className="quill-buttons">
                                <button
                                  className="btn btn--secondary btn--lock"
                                  onClick={() => {
                                    setIdEdit('')
                                    setIdReply('')
                                    setEditContent('')
                                    setSelectedFileEditReply([])
                                    setContentReply('')
                                  }}
                                >
                                  {t('DETAILS.BUTTON.CANCEL')}
                                </button>
                                <button
                                  className="btn btn--primary btn--lock replying"
                                  onClick={() => {
                                    handleReply()
                                  }}
                                >
                                  {loadingReply
                                    ? t('DETAILS.BUTTON.LOAD')
                                    : t('DETAILS.BUTTON.ANSWER')}
                                </button>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  ))}
            </div>
            {/* new messages */}
            <div className="discussion-list discussion-list--new">
              {banResponse.filter((res: any) => {
                return res.isNew
              }).length > 0 && (
                <div className="new-messages">
                  <span className="new-messages__title">
                    {t('DETAILS.NEW_MESSAGE')}
                  </span>
                </div>
              )}
              {banResponse.filter((res: any) => {
                return res.isNew
              }).length > 0 &&
                banResponse
                  .filter((res: any) => {
                    return res.isNew
                  })
                  .map((item: any, index: any) => (
                    <div
                      className="discussion-item"
                      key={item?.response_texte + item.key}
                    >
                      <div className="discussion-item__header d-flex align-items-center justify-content-between">
                        <div className="profile-status d-flex align-items-center">
                          <div className="profile-status__img">
                            <img alt="" src={item.author.photo} />
                          </div>
                          <div className="profile-status__content">
                            <a className="profile-status__name">
                              {item?.author?.name} {item?.author?.firstname}
                            </a>
                            <p className="profile-status__date date">
                              {moment(item?.createdAt)
                                .locale(lang || 'fr')
                                .fromNow()}
                            </p>
                          </div>
                        </div>
                        <div className="discussion-item__btn">
                          {apiResponse.status === 'open' && (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              onClick={() => {
                                setIdReply(item.id.toString())
                                setIdEdit('')
                                setEditContent('')
                                setSelectedFileEditReply([])
                                setStateArray([])
                                setContentReply('')
                              }}
                            >
                              <path
                                d="M9 17L4 12M4 12L9 7M4 12H16C17.0609 12 18.0783 12.4214 18.8284 13.1716C19.5786 13.9217 20 14.9391 20 16V18"
                                stroke="#004519"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          )}

                          {item?.author?.role === 3 &&
                            userInfo?.user.role.id === 4 && (
                              <button className="btn btn--primary btn--lock">
                                {t('DETAILS.MODERATOR_BUTTON')}
                              </button>
                            )}
                        </div>
                      </div>
                      <div className="discussion-item__content">
                        <p>
                          {item?.response_texte
                            ? parse(item?.response_texte)
                            : ''}
                        </p>
                        <div className="discussion-item__media">
                          <div className="row">
                            {item.file_response &&
                              item.file_response.length > 0 && (
                                <>
                                  {item.file_response
                                    .filter((file: any) =>
                                      file.mime.includes('image'),
                                    )
                                    .map((image: any, index: any) => (
                                      <div key={index} className="col-6">
                                        <div className="discussion-item__image">
                                          <img
                                            src={image.url}
                                            height="209"
                                            width={343}
                                            alt={`Image ${index + 1}`}
                                            onClick={() =>
                                              window.open(image.url, '_blank')
                                            }
                                            style={{ cursor: 'pointer' }}
                                          />
                                        </div>
                                      </div>
                                    ))}

                                  {item.file_response
                                    .filter(
                                      (file: any) =>
                                        !file.mime.includes('image'),
                                    )
                                    .map((file: any, index: any) => (
                                      <div key={index} className="col-6">
                                        <div className="file-upload file-upload--pdf">
                                          <span
                                            className="file-upload__icon--pdf"
                                            onClick={(e) => {
                                              e.stopPropagation()
                                              download(file.url)
                                            }}
                                          ></span>
                                          <span
                                            className="name"
                                            onClick={(e) => {
                                              e.stopPropagation()
                                              download(file.url)
                                            }}
                                          >
                                            {file.name}
                                          </span>{' '}
                                        </div>
                                      </div>
                                    ))}
                                </>
                              )}
                          </div>
                        </div>

                        {idReply === item.id.toString() && (
                          <>
                            <div className="quill-container">
                              <ReactQuill
                                defaultValue={`<p>@${item?.author?.name} </p`}
                                onChange={(value) =>
                                  handleQuillReply(value, index)
                                }
                                value={
                                  initialContentReply ||
                                  `<p>@${item?.author?.name} </p>`
                                }
                              />
                              <div className="add-note">
                                <label htmlFor="fileInput">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="19"
                                    height="18"
                                    viewBox="0 0 19 18"
                                    fill="none"
                                  >
                                    <path
                                      d="M10.8335 1.5V6H15.3335M9.3335 13.5V9M7.0835 11.25H11.5835M11.2085 1.5H4.8335C4.43567 1.5 4.05414 1.65804 3.77284 1.93934C3.49153 2.22064 3.3335 2.60218 3.3335 3V15C3.3335 15.3978 3.49153 15.7794 3.77284 16.0607C4.05414 16.342 4.43567 16.5 4.8335 16.5H13.8335C14.2313 16.5 14.6129 16.342 14.8942 16.0607C15.1755 15.7794 15.3335 15.3978 15.3335 15V5.625L11.2085 1.5Z"
                                      strokeWidth="1.5"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                </label>
                                <input
                                  type="file"
                                  id="fileInput"
                                  style={{ display: 'none' }}
                                  onChange={handleFileChangeEditReply}
                                  accept=".jpg, .jpeg, .png, .pdf"
                                  multiple
                                />
                              </div>
                              <div className="row w-100 block--files">
                                {selectedFilesEditReply.map((file, index) => (
                                  <div
                                    key={index}
                                    className="file-wrapper col-6"
                                  >
                                    <div className="file-upload file-upload--pdf">
                                      {file.type?.match(
                                        /image\/(jpg|jpeg|png)/,
                                      ) ? (
                                        <>
                                          <div className="profile-status__img">
                                            <img
                                              alt=""
                                              src={
                                                useObjectURLForImage(file)
                                                  ?.objectURL
                                              }
                                            />{' '}
                                          </div>
                                          <span className="name">
                                            {' '}
                                            {truncateFileName(file?.name, 15)}
                                          </span>{' '}
                                        </>
                                      ) : (
                                        <>
                                          {' '}
                                          <span className="file-upload__icon--pdf"></span>
                                          <span className="name">
                                            {truncateFileName(file?.name, 15)}
                                          </span>{' '}
                                        </>
                                      )}
                                      <span
                                        className="delete-icon"
                                        onClick={() =>
                                          handleDeleteFileEdit(index)
                                        }
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="24"
                                          height="24"
                                          viewBox="0 0 24 24"
                                          fill="none"
                                        >
                                          <path
                                            d="M15 9L9 15M9 9L15 15M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
                                            stroke="#98A2B3"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          />
                                        </svg>
                                      </span>
                                    </div>
                                  </div>
                                ))}
                              </div>
                              {isSubmittedReply &&
                                initialContentReply === '<p><br></p>' && (
                                  <>
                                    {' '}
                                    <div className="error-message">
                                      {t('DETAILS.REQUIRED_FIELD')}
                                    </div>
                                  </>
                                )}

                              <div className="quill-buttons">
                                <button
                                  className="btn btn--secondary btn--lock"
                                  onClick={() => {
                                    setIdEdit('')
                                    setIdReply('')
                                    setEditContent('')
                                    setSelectedFileEditReply([])
                                    setContentReply('')
                                  }}
                                >
                                  {t('DETAILS.BUTTON.CANCEL')}
                                </button>
                                <button
                                  className="btn btn--primary btn--lock replying"
                                  onClick={() => {
                                    handleReply()
                                  }}
                                >
                                  {loadingReply
                                    ? t('DETAILS.BUTTON.LOAD')
                                    : t('DETAILS.BUTTON.ANSWER')}
                                </button>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  ))}
            </div>
            {!loading && showResponse() && apiResponse?.nbResponse === 0 && (
              <div className="no-reply">
                <button className="btn btn--light btn__no-reply">
                  {t('DETAILS.BUTTON.NO_REPLY')}
                </button>
              </div>
            )}

            <div className="discussion-wysiwyg form-field">
              {showResponse() && (
                <>
                  {' '}
                  <label>{t('DETAILS.LABELS.MESSAGE')}</label>
                  <CKEditorComponent
                    initialContent={initialContent}
                    onChange={handleEditorChange}
                  />
                  {requiredFile() && (
                    <div className="error-message">
                      {t('DETAILS.REQUIRED_FIELD')}
                    </div>
                  )}
                  <div className="discussion-wysiwyg__btns d-md-flex justify-content-lg-between align-items-start">
                    <div className="add-note">
                      <label htmlFor="fileInput">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="19"
                          height="18"
                          viewBox="0 0 19 18"
                          fill="none"
                        >
                          <path
                            d="M10.8335 1.5V6H15.3335M9.3335 13.5V9M7.0835 11.25H11.5835M11.2085 1.5H4.8335C4.43567 1.5 4.05414 1.65804 3.77284 1.93934C3.49153 2.22064 3.3335 2.60218 3.3335 3V15C3.3335 15.3978 3.49153 15.7794 3.77284 16.0607C4.05414 16.342 4.43567 16.5 4.8335 16.5H13.8335C14.2313 16.5 14.6129 16.342 14.8942 16.0607C15.1755 15.7794 15.3335 15.3978 15.3335 15V5.625L11.2085 1.5Z"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </label>
                      <input
                        type="file"
                        id="fileInput"
                        style={{ display: 'none' }}
                        onChange={handleFileChange}
                        accept=".jpg, .jpeg, .png, .pdf"
                        multiple
                      />
                    </div>
                    <div className="row w-100 block--files">
                      {selectedFiles.map((file, index) => (
                        <div key={index} className="file-wrapper col-6">
                          <div className="file-upload file-upload--pdf">
                            {file.type?.match(/image\/(jpg|jpeg|png)/) ? (
                              <>
                                <div className="profile-status__img">
                                  <img
                                    alt=""
                                    src={useObjectURLForImage(file)?.objectURL}
                                  />{' '}
                                </div>
                                <span className="name">
                                  {' '}
                                  {truncateFileName(file.name, 15)}
                                </span>{' '}
                              </>
                            ) : (
                              <>
                                {' '}
                                <span className="file-upload__icon--pdf"></span>
                                <span className="name">
                                  {' '}
                                  {truncateFileName(file.name, 15)}
                                </span>{' '}
                              </>
                            )}
                            <span
                              className="delete-icon"
                              onClick={() => handleDeleteFile(index)}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                              >
                                <path
                                  d="M15 9L9 15M9 9L15 15M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
                                  stroke="#98A2B3"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </span>
                          </div>
                        </div>
                      ))}
                    </div>
                    <p
                      className="btn btn--primary"
                      onClick={handleSaveResponse}
                    >
                      {!loadingResponse
                        ? t('DETAILS.BUTTON.ANSWER')
                        : t('DETAILS.BUTTON.LOAD')}
                    </p>
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="col-lg-3 sidebar">
            <ul className="btn-group">
              {apiResponse?.status === 'open' && userInfo?.user.role.id === 4 && (
                <>
                  <li onClick={handleScrollDown}>
                    <p className="btn btn--primary w-100">
                      {t('DETAILS.LABELS.REPLY_DISCUSSION')}
                    </p>
                  </li>
                </>
              )}
              {apiResponse?.status === 'open' &&
                userInfo?.user.role.id === 4 &&
                apiResponse?.isCreatedByMe && (
                  <li
                    onClick={() => {
                      setShowModalThree(true)
                    }}
                  >
                    <p className="btn btn--secondary w-100">
                      {t('DETAILS.LABELS.EDIT_DISCUSSION')}
                    </p>
                  </li>
                )}
            </ul>
            {userInfo?.user?.role?.id !== 3 &&
              apiResponse?.status === 'closed' && (
                <div className="message">
                  {t('DETAILS.LABELS.MESSAGE_LOCK')}
                </div>
              )}
            {userInfo?.user?.role?.id === 3 && (
              <>
                <div className="config">
                  <h3 className="config__heading">
                    {t('DETAILS.LABELS.MODERATION')}
                  </h3>
                  <h4 className="config__subtitle">
                    {t('DETAILS.LABELS.DISPLAY_SETTINGS')}
                  </h4>
                  <ul className="config__list">
                    <li>
                      <div className="switch">
                        <input
                          type="checkbox"
                          checked={pinned}
                          onChange={async () => {
                            setPinned(!pinned)
                            await discussionService.pinUnpin(
                              slug || 'fr',
                              !pinned,
                            )
                            setApiResponse((prevData: any) => ({
                              ...prevData,
                              isPin: !prevData.isPin,
                            }))
                          }}
                        ></input>
                        <label>{t('DETAILS.LABELS.DISCUSSION_PIN')}</label>
                      </div>
                    </li>
                    <li>
                      <div className="switch">
                        <input
                          type="checkbox"
                          checked={locked}
                          onChange={async () => {
                            setLocked(!locked)
                            await discussionService.activeReactive(
                              slug || 'fr',
                              !locked ? 'closed' : 'open',
                            )
                            setApiResponse((prevData: any) => ({
                              ...prevData,
                              status: !locked ? 'closed' : 'open',
                            }))
                          }}
                        ></input>
                        <label>{t('DETAILS.LABELS.DISCUSSION_LOCK')}</label>
                      </div>
                    </li>
                  </ul>
                  <h4 className="config__subtitle">
                    {t('DETAILS.LABELS.DISPLAY_SETTINGS')}
                  </h4>
                  <ul className="config__list">
                    <li
                      onClick={() => {
                        setShowModalThree(true)
                      }}
                    >
                      <a className="btn-w-icon" href="#">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                        >
                          <path
                            d="M3.33301 11.2503V3.33366C3.33301 2.89163 3.5086 2.46771 3.82116 2.15515C4.13372 1.84259 4.55765 1.66699 4.99967 1.66699H12.083L16.6663 6.25033V16.667C16.6663 17.109 16.4907 17.5329 16.1782 17.8455C15.8656 18.1581 15.4417 18.3337 14.9997 18.3337H10.4163M11.6663 1.66699V6.66699H16.6663M8.68301 10.5086C8.84552 10.3461 9.03845 10.2172 9.25078 10.1293C9.46311 10.0413 9.69068 9.99605 9.92051 9.99605C10.1503 9.99605 10.3779 10.0413 10.5902 10.1293C10.8026 10.2172 10.9955 10.3461 11.158 10.5086C11.3205 10.6712 11.4494 10.8641 11.5374 11.0764C11.6253 11.2887 11.6706 11.5163 11.6706 11.7461C11.6706 11.976 11.6253 12.2035 11.5374 12.4159C11.4494 12.6282 11.3205 12.8211 11.158 12.9836L6.62467 17.5003L3.33301 18.3336L4.15801 15.042L8.68301 10.5086Z"
                            strokeWidth="1.66667"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                        {t('DETAILS.LABELS.MODIFY_TITLE')}
                      </a>
                    </li>
                    <li
                      onClick={() => {
                        setModalShowTwo(true)
                      }}
                    >
                      <a className="btn-w-icon" href="#">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                        >
                          <path
                            d="M2.5 5.00033H17.5M15.8333 5.00033V16.667C15.8333 17.5003 15 18.3337 14.1667 18.3337H5.83333C5 18.3337 4.16667 17.5003 4.16667 16.667V5.00033M6.66667 5.00033V3.33366C6.66667 2.50033 7.5 1.66699 8.33333 1.66699H11.6667C12.5 1.66699 13.3333 2.50033 13.3333 3.33366V5.00033M8.33333 9.16699V14.167M11.6667 9.16699V14.167"
                            stroke-width="1.66667"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                        {t('DETAILS.LABELS.DELETE')}
                      </a>
                    </li>
                  </ul>
                </div>
                {userInfo?.user.role.id === 3 && (
                  <a
                    className="btn btn--primary w-100"
                    onClick={handleScrollDown}
                  >
                    {t('DETAILS.LABELS.REPLY_DISCUSSION')}
                  </a>
                )}
              </>
            )}
          </div>
        </div>
      </div>

      <ModalComponent
        show={modalShow}
        idResponse={idToDelete}
        isMyAnswer={isAnswer}
        onClose={(response) => {
          handleCloselOpen(response)
        }}
      />
      <ModalDeleteDiscussion
        show={modalShowTwo}
        idDiscussion={slug || ''}
        onClose={() => {
          setModalShowTwo(false)
        }}
      />

      <DiscussionCreateModale
        onOpen={() => {
          setShowModalThree(true)
        }}
        isEdit={true}
        show={modalShowThree}
        nameEdit={apiResponse?.name || ''}
        contentDiscussion={apiResponse?.descriptions || ''}
        idDiscussion={slug || ''}
        onClose={(data) => {
          handleModalCloseEditDiscuss(data as any)
        }}
      />
    </>
  )
}

export default DetailsDiscussion
