import axios from 'axios';
const API_URL = process.env.REACT_APP_REST_API_LOCATION;

const orderService = {
  confirmOrder: async (params: any) => {
    try {
      const response = await axios.post(`${API_URL}/order-confirmation`, {
        name: params.name,
        locale: params.locale,
        firstname: params.firstname,
        productName: params.productName,
        price: params.price,
        url: params.url,
      });
      return response.data;
    } catch (error) {
      console.error('Error during order confirmation request:', error);
      throw error;
    }
  },
};

export default orderService;
