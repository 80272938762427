import axios from 'axios';
import { Store } from 'redux';
import { setTokenExpired } from 'stores/slices/token-slice';

const setupAxiosInterceptor = (store: Store) => {
  const { dispatch } = store;
  // console.log('store.getState()', store.getState());
  axios.interceptors.request.use(
    (config: any) => {
      if (store.getState().users?.userInfo?.jwt) {
        config.headers['Authorization'] = `Bearer ${
          store.getState().users?.userInfo?.jwt
        }`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  axios.interceptors.response.use(
    (res) => {
      return res;
    },

    async (err) => {
      const originalConfig = err.config;
      if (err?.response) {
        if (err?.response?.status === 401) {
          originalConfig._retry = true;

          try {
            dispatch(setTokenExpired(true));
          } catch (_error) {
            return Promise.reject(_error);
          }
        }
      }

      return Promise.reject(err);
    }
  );
};

export default setupAxiosInterceptor;
