import LoaderGameiro from 'components/LoaderGameiro/Loader'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import {
  searchDiscussions,
  selectAllDiscussions,
  updateSearch,
} from 'stores/slices/discussion-slice'
import DiscussionList from './Discuss'
import './style.scss'

const Discussion: React.FC = () => {
  const { lang } = useParams() || 'fr'
  const dispatch = useDispatch()
  const { isSearch, statusSearch } = useSelector(selectAllDiscussions)
  const { t } = useTranslation()
  const [searchTerm, setSearchTerm] = useState<string>('')

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value)
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      // Perform the search if the value is not empty
      if (searchTerm.trim() !== '') {
        dispatch(
          searchDiscussions({
            locale: lang || 'fr',
            clef: searchTerm,
          }) as any,
        )
        console.log('Performing search with term:', searchTerm)
      } else {
        dispatch(updateSearch(true))
        setSearchTerm('')
      }
    }
  }
  const back = () => {
    dispatch(updateSearch(true))
    setSearchTerm('')
  }

  const resetSearch = () => {
    setSearchTerm('')
  }
  return (
    <>
      <div
        className={`${
          isSearch ? 'banner banner--with-search' : 'banner banner--with-border'
        } `}
      >
        <div className="container container--small">
          {!isSearch ? (
            <>
              <h1 className="banner__bigtitle"> {t('HEADER.Discussion')}</h1>
              <p className="banner__subtitle">{t('HEADER.DISCUSSION_TITLE')}</p>
            </>
          ) : (
            <>
              <div
                className="discussion__back d-flex align-items-center"
                onClick={back}
              >
                <span className="d-inline-flex">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M12 19L5 12M5 12L12 5M5 12H19"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </span>
                Retour aux discussions
              </div>
            </>
          )}

          <div className="banner__search">
            {statusSearch !== 'loading' && (
              <span className="banner__iconsearch">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="21"
                  height="20"
                  viewBox="0 0 21 20"
                  fill="none"
                >
                  <path
                    d="M18 17.5L14.375 13.875M16.3333 9.16667C16.3333 12.8486 13.3486 15.8333 9.66667 15.8333C5.98477 15.8333 3 12.8486 3 9.16667C3 5.48477 5.98477 2.5 9.66667 2.5C13.3486 2.5 16.3333 5.48477 16.3333 9.16667Z"
                    stroke="#004519"
                    strokeWidth="1.66667"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>
            )}
            <input
              type="text"
              className="form-control"
              id="search"
              name="search"
              placeholder="Rechercher"
              value={searchTerm}
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
            />
            {searchTerm && (
              <>
                {' '}
                <span className="banner__iconclose" onClick={resetSearch}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <g clip-path="url(#clip0_1297_4367)">
                      <path
                        d="M15.1822 9.09109L9.36404 14.9093M9.36404 9.09109L15.1822 14.9093M21.9701 12.0002C21.9701 17.3557 17.6286 21.6971 12.2731 21.6971C6.91765 21.6971 2.57617 17.3557 2.57617 12.0002C2.57617 6.6447 6.91765 2.30322 12.2731 2.30322C17.6286 2.30322 21.9701 6.6447 21.9701 12.0002Z"
                        stroke="#98A2B3"
                        stroke-width="2.32727"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_1297_4367">
                        <rect
                          width="23.2727"
                          height="23.2727"
                          fill="white"
                          transform="translate(0.636719 0.36377)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </span>
              </>
            )}
            {statusSearch === 'loading' && (
              <LoaderGameiro
                bg="loader-custom--transparent"
                type="loader-custom--relative"
              />
            )}
          </div>
        </div>
      </div>
      <DiscussionList />{' '}
    </>
  )
}

export default Discussion
