import React from 'react';
import './style.scss'; 

interface ArrowUpRight1Props {
  color?: string;
  className?: string;
}

export const ArrowUpRight1: React.FC<ArrowUpRight1Props> = ({
  color = "#101828",
  className = ""
}) => {
  return (
    <svg
      className={`arrow-up-right-1 ${className}`}
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M7 17L17 7M17 7H7M17 7V17"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </svg>
  );
};
