import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import discussionService from 'services/discussion.service';
import { RootState } from 'stores/store';
// TODO: type && SEPARETE FILE

export interface Discussion {
  id: number;
  createdAt: string;
  last_reply: string;
  categorieID: number;
  responses: number;
  isMember: boolean;
  usersPins: boolean;
  name: string;
  categoryName?: string;
  pilote: {
    id: number;
    name: string;
    firstname: string;
    photo: string;
  };
  createdByme: boolean;
  isNew: boolean;
  discussions: Discussion[];
  searcResurlt: Discussion[];
  notifications: any[]; // TODO: type
}

interface DiscussionState {
  discussions: Discussion[];
  notifications: any[];
  searcResurlt: Discussion[];
  selectedDiscussion: Discussion | null;
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  statusSearch: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
  isSearch?: boolean;
  pagination: {
    page: number;
    pageSize: number;
    pageCount: number;
    total: number;
  };
  currentCategorie?: number;
}

const initialState: DiscussionState = {
  discussions: [],
  notifications: [],
  searcResurlt: [],
  selectedDiscussion: null,
  status: 'idle',
  statusSearch: 'idle',
  error: null,
  isSearch: false,
  pagination: {
    page: 1,
    pageSize: 12,
    pageCount: 1,
    total: 4,
  },
};

const sortArrayByCategories = (array1: any, array2: any) => {
  const categoriesMap = array2.reduce((acc: any, item: any) => {
    acc[item.categorieID] = true;
    return acc;
  }, {});

  const sortedArray1 = array1.sort((a: any, b: any) => {
    const isInArray2A = categoriesMap[a.categorieID] !== undefined;
    const isInArray2B = categoriesMap[b.categorieID] !== undefined;

    if (isInArray2A && isInArray2B) {
      const indexA = array2.findIndex(
        (item: any) => item.categorieID === a.categorieID
      );
      const indexB = array2.findIndex(
        (item: any) => item.categorieID === b.categorieID
      );
      return indexA - indexB;
    } else if (isInArray2A) {
      return -1;
    } else if (isInArray2B) {
      return 1;
    } else {
      return 0;
    }
  });

  return sortedArray1;
};

export const fetchDiscussions = createAsyncThunk(
  'discussion/fetchDiscussions',
  async (data: {
    locale: string;
    page: number;
    isPaginate?: boolean;
    categoryId: string;
  }) => {
    const response = await discussionService.list(
      data.locale,
      data.page.toString(),
      data.categoryId
    );

    return {
      data: response.results,
      isPaginate: data.isPaginate,
      notifications: response.notifications,
      hasID: data.categoryId !== null,
    };
  }
);

export const searchDiscussions = createAsyncThunk(
  'discussion/searchDiscussions',
  async (data: { locale: string; clef: string }) => {
    const response = await discussionService.search(
      data.locale,
      data.clef.toString()
    );

    return {
      data: response.results,
    };
  }
);

const discussionSlice = createSlice({
  name: 'discussions',
  initialState,
  reducers: {
    setDiscussionSelected: (state, action: PayloadAction<number>) => {
      state.selectedDiscussion =
        state.discussions.find(
          (discussion) => discussion.id === action.payload
        ) || null;
    },

    setCurrentCategorie: (state, action: PayloadAction<number>) => {
      state.currentCategorie = action.payload;
    },

    updateSearch(state, action: PayloadAction<boolean>) {
      state.isSearch = false;
      if (!state.isSearch) {
        state.searcResurlt = [];
      }
    },

    clearDiscussions: (state) => {
      state.discussions = [];
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchDiscussions.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchDiscussions.fulfilled, (state, action) => {
        if (action?.payload?.hasID) {
          state.status = 'succeeded';
        }
        state.notifications = action?.payload?.notifications || [];
        if (!action.payload?.isPaginate) {
          state.discussions = sortArrayByCategories(
            action?.payload?.data?.discussions || [],
            action?.payload?.notifications
          );
          state.pagination = action?.payload?.data?.pagination;
        } else {
          console.log('concate');

          const newDiscussions = action?.payload?.data?.discussions || [];
          const existingCategory = state.discussions.find(
            (category) =>
              category.categoryName === newDiscussions[0]?.categoryName
          );

          if (existingCategory) {
            existingCategory.discussions = [
              ...existingCategory.discussions,
              ...newDiscussions[0]?.discussions,
            ];
          }

          state.pagination = action?.payload?.data?.pagination;
        }
      })
      .addCase(fetchDiscussions.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action?.error?.message || null;
      });

    builder
      .addCase(searchDiscussions.pending, (state) => {
        state.statusSearch = 'loading';
      })
      .addCase(searchDiscussions.fulfilled, (state, action) => {
        state.statusSearch = 'succeeded';
        state.isSearch = true;
        state.searcResurlt = action?.payload?.data || [];
      })
      .addCase(searchDiscussions.rejected, (state, action) => {
        state.statusSearch = 'failed';
        state.error = action?.error?.message || null;
      });
  },
});

export const {
  setDiscussionSelected,
  setCurrentCategorie,
  updateSearch,
  clearDiscussions,
} = discussionSlice.actions;

export const selectAllDiscussions = (state: RootState) => state.discussions;

export const selectSelectedDiscussion = (state: RootState) =>
  state.discussions.selectedDiscussion;

export default discussionSlice.reducer;
