import axios from 'axios';
const API_URL = process.env.REACT_APP_REST_API_LOCATION;

const discussionService = {
  list: async (locale: string, page: string, categoryId: string) => {
    try {
      const response = await axios.get(
        `${API_URL}/discussion-pilote?locale=${locale}&page=${page}&categorieID=${
          categoryId || ''
        }`
      );
      return response.data;
    } catch (error) {
      console.error('Error during login request:', error);
      throw error;
    }
  },

  create: async (
    name: string,
    descriptions: string,
    locale: string,
    categorieID: number
  ) => {
    try {
      const response = await axios.post(`${API_URL}/create-discussion`, {
        name: name,
        descriptions: descriptions,
        locale: locale || 'fr',
        categorieID: categorieID,
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  delete: async (id: string) => {
    try {
      const response = await axios.delete(`${API_URL}/discussion-remove/${id}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  update: async (id: string, name: string, desc: string) => {
    try {
      const response = await axios.put(`${API_URL}/discussion-update/${id}`, {
        name: name,
        descriptions: desc,
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  pinUnpin: async (id: string, status: boolean) => {
    try {
      const response = await axios.put(
        `${API_URL}/discussion-pin-unpin/${id}`,
        {
          isPin: status || null,
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  activeReactive: async (id: string, status: string) => {
    try {
      const response = await axios.put(`${API_URL}/discussion-disabled/${id}`, {
        status: status,
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  search: async (locale: string, clef: string) => {
    try {
      const response = await axios.get(
        `${API_URL}/search-discussion?locale=${locale}&search=${clef}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  setActivity: async (id: string) => {
    try {
      const response = await axios.get(`${API_URL}/activity/${id}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
};

export default discussionService;
