/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Nav from 'react-bootstrap/Nav'
import { useSelector } from 'react-redux'
import { Link, useLocation, useParams } from 'react-router-dom'
import { selectAllDiscussions } from 'stores/slices/discussion-slice'
import { selectUserInfo } from 'stores/slices/user-slice'
import './style-connected.scss'

const HeaderConnected: React.FC = () => {
  const userInfo = useSelector(selectUserInfo)
  const { t } = useTranslation()
  const [showDropdown, setShowDropdown] = useState(false)
  const [isProfilPresent, setIsProfilPresent] = useState(false)

  const { lang } = useParams() || 'fr'
  const location = useLocation()

  const handleDropdownToggle = () => {
    setShowDropdown(!showDropdown)
  }
  const { notifications } = useSelector(selectAllDiscussions)
  const specificString = 'profil'
  const checkActive = (path: string): boolean => {
    return location.pathname === `/${lang}${path}`
  }

  useEffect(() => {
    const checkStringPresence = location.pathname.includes(specificString)
    setIsProfilPresent(checkStringPresence)

    if (checkStringPresence) {
    }
  }, [location])

  return (
    <>
      <header
        className={`header ${
          isProfilPresent
            ? 'isNotConnected'
            : location.pathname.includes('/discussion')
            ? 'header--connected'
            : 'isConnected '
        }`}
      >
        <nav className="navbar navbar-expand-lg">
          <div className="d-md-flex align-items-md-center justify-content-md-between">
            {isProfilPresent || location.pathname.includes('/discussion') ? (
              <Link to={`/${lang}`} className="navbar-brand">
                <img alt="logo" src="/logo.svg"></img>
              </Link>
            ) : (
              <>
                <Link to={`/${lang}`} className="navbar-brand">
                  <img alt="logo" src="/brand.png"></img>
                </Link>
              </>
            )}
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarNav"
              aria-controls="navbarNav"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="burger-menu"></span>
            </button>
            {/* menu */}
            <ul className="navbar-nav ml-auto">
              <li className="nav-item">
                <Nav.Link
                  as={Link}
                  to={`/${lang}/discussion`}
                  className={`${checkActive('/discussion') ? 'active' : ''}`}
                >
                 { t('HEADER.Discussion') }
                  {notifications.length > 0 && (
                    <span className="nav-bullet"></span>
                  )}
                </Nav.Link>
              </li>
              <li className="nav-item">
                <Nav.Link
                  as={Link}
                  to={`/${lang}/purchasing`}
                  className={`${checkActive('/purchasing') ? 'active' : ''}`}
                >
                 { t('HEADER.Central') }
                </Nav.Link>
              </li>
              <li className="nav-item">
                <Nav.Link
                  as={Link}
                  to={`/${lang}/support`}
                  className={`${checkActive('/support') ? 'active' : ''}`}
                >
                 { t('HEADER.Support') }
                </Nav.Link>
              </li>
            </ul>
            <div
              className="nav-right d-flex align-items-center"
              onClick={handleDropdownToggle}
            >
              <div
                className="profile"
                style={{
                  backgroundImage: `url(${userInfo?.user?.photo})`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  width: '32px',
                  height: '32px',
                  borderRadius: '5px',
                }}
              ></div>
              <span className="username">
                <Link to={`/${lang}/profil`} className="name">
                  {' '}
                  {userInfo?.user?.firstname + ' ' + userInfo?.user?.name}{' '}
                </Link>
              </span>
            </div>
          </div>
        </nav>
      </header>
    </>
  )
}

export default HeaderConnected
