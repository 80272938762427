import React from 'react'
import './index.scss'

interface DynamicTextProps {
  bg?: string
  type?: string
}

const LoaderGameiro: React.FC<DynamicTextProps> = ({
  bg = '',
  type = 'loader-custom--fixed',
}) => {
  return (
    <div
      className={`loader-custom ${bg} ${type} d-flex justify-content-center align-items-center`}
    >
      {type === 'loader-custom--absolute' ? (
        <div className="blink">
          <img
            alt="logo"
            src="/logo-green.svg"
            className="logo"
            draggable="false"
          />
        </div>
      ) : (
        <div className="loader" />
      )}
    </div>
  )
}

export default LoaderGameiro
