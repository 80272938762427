import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import purchaseSupportService from 'services/purchase-support.service';
import { RootState } from 'stores/store';

export interface Support {
  id: number;
  title: string;
  locale: string;
  descriptions: string;
  photo: any;
  file_source: string;
}

interface SupportsState {
  supports: Support[];
  selectedSupport?: Support | null;
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
}

const initialState: SupportsState = {
  supports: [],
  selectedSupport: null,
  status: 'idle',
  error: null,
};

export const fetchSupports = createAsyncThunk(
  'supports/fetchSupports',
  async (locale?: string) => {
    const response = await purchaseSupportService.listSupport(locale || 'fr');
    return response;
  }
);

const supportsSlice = createSlice({
  name: 'supports',
  initialState,
  reducers: {
    setSelectedSupport: (state, action: PayloadAction<Support | null>) => {
      state.selectedSupport = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSupports.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchSupports.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.supports = action?.payload;
      })
      .addCase(fetchSupports.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message ?? "Une erreur s'est produite.";
      });
  },
});

export const { setSelectedSupport } = supportsSlice.actions;

export const getSelectedSupport = (state: RootState) =>
  state.supports.selectedSupport;

export const selectSupports = (state: RootState) => state.supports;

export default supportsSlice.reducer;
