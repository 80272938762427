import axios from 'axios';
const API_URL = process.env.REACT_APP_REST_API_LOCATION;

const purchaseSupportService = {
  listAchat: async (locale?: string) => {
    try {
      const response = await axios.get(
        `${API_URL}/get-product?locale=${locale}`
      );
      return response.data;
    } catch (error) {
      console.error('Error during login request:', error);
      throw error;
    }
  },

  listSupport: async (locale?: string) => {
    try {
      const response = await axios.get(
        `${API_URL}/get-supprort?locale=${locale}`
      );
      return response.data;
    } catch (error) {
      console.error('Error during login request:', error);
      throw error;
    }
  },
};

export default purchaseSupportService;
