import LoaderGameiro from 'components/LoaderGameiro/Loader'
import DiscussionCreateModale from 'components/Modal/Discussion/DiscussionCreate'
import moment from 'moment'
import React, { useCallback, useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import {
  Discussion,
  clearDiscussions,
  fetchDiscussions,
  selectAllDiscussions,
} from 'stores/slices/discussion-slice'

export const TabSwitcher: React.FC = () => {
  const { lang } = useParams() || 'fr'
  const [selectedTab, setSelectedTab] = useState(0)
  const [loadingPaginate, setLoadingPaginate] = useState(false)
  const [categorie, setCategorie] = useState('')
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const {
    discussions,
    status,
    error,
    searcResurlt,
    isSearch,
    pagination,
    notifications,
  } = useSelector(selectAllDiscussions)
  const handleTabClick = (index: number) => {
    setSelectedTab(index)
    setCategorie(discussions[index].categorieID.toString())
  }
  useEffect(() => {
    if (status === 'succeeded') {
      setLoadingPaginate(false)
    }
  }, [status])
  const [modalShow, setModalShow] = useState(false)

  const handleModalOpen = () => {
    setModalShow(true)
  }

  const viewDiscussion = (id: number) => {
    setCategorie('')
    dispatch(clearDiscussions())
    navigate(`/${lang}/discussion-details/${id}`)
  }

  useEffect(() => {
    dispatch(
      fetchDiscussions({
        locale: lang || 'fr',
        page: 1,
        categoryId: categorie || '',
      }) as any,
    )
  }, [dispatch, lang, selectedTab, categorie])

  useEffect(() => {
    if (discussions.length > 0 && !categorie) {
      if (!categorie) {
        setCategorie(discussions[selectedTab].categorieID.toString())
      }
    }
  }, [categorie, discussions, selectedTab])

  const handleModalClose = (data?: boolean) => {
    setModalShow(false)

    if (data) {
      dispatch(
        fetchDiscussions({
          locale: lang || 'fr',
          page: 1,
          isPaginate: false,
          categoryId: discussions[selectedTab]?.categorieID.toString(),
        }) as any,
      )
    }
  }

  const handleScroll = useCallback(() => {
    const isBottom =
      window.innerHeight + document.documentElement.scrollTop ===
      document.documentElement.offsetHeight

    if (isBottom) {
      if (pagination?.pageCount > pagination?.page) {
        setLoadingPaginate(true)
        dispatch(
          fetchDiscussions({
            locale: lang || 'fr',
            page: pagination.page + 1,
            isPaginate: true,
            categoryId: '1',
          }) as any,
        )
      }
    }
  }, [dispatch, lang, pagination?.page, pagination?.pageCount])

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [handleScroll])

  return (
    <>
      <div className="container">
        <div className="discussion__wrapper">
          {!isSearch && (
            <div className="discussion__tabs">
              {discussions.length > 0 &&
                discussions.map((category, index) => (
                  <span className="discussion__btn">
                    <button
                      key={index}
                      onClick={() => handleTabClick(index)}
                      className={`btn btn--normal btn--normal-regular btn--normal-no-bg ${
                        index === selectedTab ? 'btn--normal-bold' : ''
                      }`}
                    >
                      {category?.categoryName}{' '}
                      {notifications.length > 0 &&
                        notifications.filter((res: any) => {
                          return res.categorieID === category.categorieID
                        }).length > 0 && (
                          <span className="discussion__bullet"></span>
                        )}
                    </button>
                  </span>
                ))}
            </div>
          )}
          {!isSearch &&
            status === 'succeeded' &&
            discussions[selectedTab]?.discussions?.length === 0 && (
              <div className="discussion__msg">
                <div className="discussion__icon">
                  <img
                    src="/np_time_4479397_000000 1.png"
                    alt="Header Imagery"
                  />
                </div>
                <h3 className="discussion__subtitle">
                  Actuellement, aucune discussion récente n'a été initiée pour{' '}
                  {discussions[selectedTab]?.categoryName?.toLowerCase()}.
                </h3>
              </div>
            )}
          {!isSearch && (
            <div className="discussion__infos">
              {discussions[selectedTab]?.discussions?.length > 0 &&
                discussions[selectedTab]?.discussions?.filter((res) => {
                  return res?.isNew === true
                })?.length > 0 &&
                categorie !== '' && (
                  <>
                    <div className="discussion__list">
                      <div className="discussion__msg">
                        <div className="discussion__icon">
                          <img src="/stars.png" alt="Header Imagery" />
                        </div>
                        <h3 className="discussion__subtitle">
                          Discussions avec de nouveaux messages{' '}
                        </h3>
                      </div>{' '}
                      <ul className="list-message">
                        {discussions[selectedTab]?.discussions
                          ?.filter((res) => {
                            return res?.isNew === true
                          })
                          .map((discussion: Discussion) => (
                            <li
                              className="list-message__item"
                              onClick={() => {
                                viewDiscussion(discussion.id)
                              }}
                            >
                              <div className="list-message__top d-flex align-items-start">
                                <h3 className="list-message__title">
                                  {discussion.name}
                                </h3>
                                <img
                                  src="/arrow-up-right.png"
                                  alt="Icon images"
                                />
                              </div>
                              <div className="message-info d-flex">
                                <div className="message-info__icon">
                                  <img
                                    src={discussion?.pilote?.photo}
                                    alt="Header Imagery"
                                  />
                                </div>
                                <p className="message-info__name">
                                  {discussion?.pilote?.name}{' '}
                                  {discussion?.pilote?.firstname}
                                </p>
                                <ul className="list-notif">
                                  <li className="list-notif__item">
                                    Posté{' '}
                                    {moment(discussion.createdAt)
                                      .locale(lang || 'fr')
                                      .fromNow()}
                                  </li>
                                  <li className="list-notif__item">
                                    {discussion?.responses} {''}
                                    {discussion?.responses > 1
                                      ? 'réponses.'
                                      : 'réponse.'}
                                  </li>
                                </ul>
                              </div>
                            </li>
                          ))}
                      </ul>
                    </div>
                  </>
                )}

              {status === 'loading' && (
                <>
                  {' '}
                  <LoaderGameiro />
                </>
              )}

              {status !== 'loading' &&
                discussions[selectedTab]?.discussions?.length > 0 &&
                discussions[selectedTab]?.discussions?.filter((res) => {
                  return res?.usersPins === true
                })?.length > 0 &&
                categorie !== '' && (
                  <div className="discussion__list">
                    <div className="discussion__msg">
                      <div className="discussion__icon">
                        <img src="/pin.png" alt="Header Imagery" />
                      </div>
                      <h3 className="discussion__subtitle">
                        Discussions épinglées
                      </h3>
                    </div>
                    <ul className="list-message list-message--no-border">
                      {discussions[selectedTab]?.discussions
                        ?.filter((res: Discussion) => {
                          return res?.usersPins === true
                        })
                        .map((discussion) => (
                          <li
                            className="list-message__item"
                            onClick={() => {
                              viewDiscussion(discussion.id)
                            }}
                          >
                            <div className="list-message__top d-flex align-items-start">
                              <h3 className="list-message__title">
                                {discussion.name}
                              </h3>
                              <img
                                src="/arrow-up-right.png"
                                alt="Icon images"
                              />
                            </div>
                            <div className="message-info d-flex">
                              <div className="message-info__icon">
                                <img
                                  src={discussion?.pilote?.photo}
                                  alt="Header Imagery"
                                />
                              </div>
                              <p className="message-info__name">
                                {discussion?.pilote?.name}{' '}
                                {discussion?.pilote?.firstname}
                              </p>
                              <ul className="list-notif">
                                <li className="list-notif__item">
                                  {' '}
                                  Posté{' '}
                                  {moment(discussion.createdAt)
                                    .locale(lang || 'fr')
                                    .fromNow()}
                                </li>
                                <li className="list-notif__item">
                                  {' '}
                                  {discussion?.responses} {''}
                                  {discussion?.responses > 1
                                    ? 'réponses.'
                                    : 'réponse.'}
                                </li>
                              </ul>
                            </div>
                          </li>
                        ))}
                    </ul>
                  </div>
                )}
              {status !== 'loading' &&
                discussions[selectedTab]?.discussions?.length > 0 &&
                discussions[selectedTab]?.discussions?.filter((res) => {
                  return !res?.usersPins && !res.isNew
                })?.length > 0 &&
                categorie !== '' && (
                  <div className="discussion__list">
                    <div className="discussion__msg">
                      <div className="discussion__icon">
                        <img
                          src="/np_time_4479397_000000 1.png"
                          alt="Header Imagery"
                        />
                      </div>
                      <h3 className="discussion__subtitle">
                        Dernières discussions
                      </h3>
                    </div>
                    <ul className="list-message list-message--no-border">
                      {discussions[selectedTab]?.discussions?.length > 0 &&
                        discussions[selectedTab]?.discussions
                          ?.filter((res) => {
                            return !res?.usersPins && !res.isNew
                          })
                          .map((discussion) => (
                            <li
                              className="list-message__item"
                              onClick={() => {
                                viewDiscussion(discussion.id)
                              }}
                            >
                              <div className="list-message__top d-flex align-items-start">
                                <h3 className="list-message__title">
                                  {discussion.name}
                                </h3>
                                <img
                                  src="/arrow-up-right.png"
                                  alt="Icon images"
                                />
                              </div>
                              <div className="message-info d-flex">
                                <div className="message-info__icon">
                                  <img
                                    src={discussion?.pilote?.photo}
                                    alt="Header Imagery"
                                  />
                                </div>
                                <p className="message-info__name">
                                  {discussion?.pilote?.name}{' '}
                                  {discussion?.pilote?.firstname}
                                </p>
                                <ul className="list-notif">
                                  <li className="list-notif__item">
                                    {' '}
                                    Posté{' '}
                                    {moment(discussion.createdAt)
                                      .locale(lang || 'fr')
                                      .fromNow()}
                                  </li>
                                  <li className="list-notif__item">
                                    {discussion?.responses} {''}
                                    {discussion?.responses > 1
                                      ? 'réponses.'
                                      : 'réponse.'}
                                  </li>
                                </ul>
                              </div>
                            </li>
                          ))}
                    </ul>
                  </div>
                )}
              <div className="discussion__create">
                <Button
                  className="btn btn--primary"
                  variant="secondary"
                  onClick={handleModalOpen}
                >
                  Créer une nouvelle discussion
                </Button>
              </div>
            </div>
          )}
          {isSearch && (
            <>
              {' '}
              <div className="discussion__results">
                <p className="discussion__subtitle">
                  {searcResurlt.length} résultat
                  {searcResurlt.length > 0 ? 's' : ''} de recherche
                </p>

                <div className="discussion__infos">
                  <div className="discussion__list">
                    {' '}
                    <ul className="list-message list-message--no-border">
                      {searcResurlt.length > 0 &&
                        searcResurlt.map((discussion) => (
                          <li
                            className="list-message__item"
                            onClick={() => {
                              viewDiscussion(discussion.id)
                            }}
                          >
                            <div className="list-message__top d-flex align-items-start">
                              <h3 className="list-message__title">
                                {discussion.name}
                              </h3>
                              <img
                                src="/arrow-up-right.png"
                                alt="Icon images"
                              />
                            </div>
                            <div className="message-info d-flex">
                              <div className="message-info__icon">
                                <img
                                  src={discussion?.pilote?.photo}
                                  alt="Header Imagery"
                                />
                              </div>
                              <p className="message-info__name">
                                {discussion?.pilote?.name}{' '}
                                {discussion?.pilote?.firstname}
                              </p>
                              <ul className="list-notif">
                                <li className="list-notif__item">
                                  {' '}
                                  Posté{' '}
                                  {moment(discussion.createdAt)
                                    .locale(lang || 'fr')
                                    .fromNow()}
                                </li>
                                <li className="list-notif__item">
                                  {' '}
                                  {discussion?.responses} {''}
                                  {discussion?.responses > 1
                                    ? 'réponses.'
                                    : 'réponse.'}
                                </li>
                              </ul>
                            </div>
                          </li>
                        ))}
                    </ul>
                  </div>
                </div>
              </div>
            </>
          )}{' '}
          {loadingPaginate && (
            <LoaderGameiro
              bg="loader-custom--transparent"
              type="loader-custom--relative"
            />
          )}
        </div>

        <DiscussionCreateModale
          show={modalShow}
          onOpen={handleModalOpen}
          onClose={(data) => {
            handleModalClose(data)
          }}
          lang={lang || 'fr'}
          idCategorie={discussions[selectedTab]?.categorieID}
        />
      </div>
    </>
  )
}
