import CKEditorComponent from 'components/CKEditorComponent/CKEditorComponent'
import LoaderGameiro from 'components/LoaderGameiro/Loader'
import React, { ReactNode, useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import discussionService from 'services/discussion.service'
import './style.scss'

interface DiscussionCreateModalProps {
  content?: ReactNode
  show: boolean
  idCategorie?: number
  isEdit?: boolean
  idDiscussion?: string
  lang?: string
  nameEdit?: string
  contentDiscussion?: string
  onClose: (isData?: boolean) => void
  onOpen: () => void
}

const DiscussionCreateModale: React.FC<DiscussionCreateModalProps> = ({
  content,
  show,
  onClose,
  idCategorie,
  onOpen,
  contentDiscussion,
  lang,
  nameEdit,
  isEdit,
  idDiscussion,
}) => {
  const [initialContent, setContent] = useState<string>('')
  const [name, setName] = useState('')
  const [isSubmitted, setSubmitted] = useState(false)
  const [loading, setLoading] = useState(false)
  const { t } = useTranslation()
  const handleEditorChange = (newContent: string) => {
    setContent(newContent)
  }

  useEffect(() => {
    if (isEdit) {
      setName(nameEdit || '')
      setContent(contentDiscussion || '')
    }
  }, [contentDiscussion, isEdit, nameEdit, show])

  const handleButtonClick = async () => {
    setSubmitted(true)
    if (name.trim() === '' || initialContent.trim() === '' || requiredFile()) {
      toast.error(t('PROFILS.ERROR.HANDLE_ERROR'))
    } else {
      if (!isEdit) {
        setLoading(true)
        try {
          const data = await discussionService.create(
            name,
            initialContent,
            lang || 'fr',
            idCategorie || 0,
          )
          if (data) {
            setLoading(false)
            onClose(true)
            setSubmitted(false)
            setName('')
            setContent('')
            toast.success(t('DETAILS.DISCUSSION.SAVE_SUCCESS'))
          }
        } catch (error) {
          setLoading(false)
          toast.error(
            t('DETAILS.DISCUSSION.SAVE_ERROR'),
          )
          console.log('error')
        }
      } else {
        try {
          const data = await discussionService.update(
            idDiscussion || '',
            name,
            initialContent,
          )
          setLoading(true)
          if (data) {
            setLoading(false)
            onClose(true)
            setSubmitted(false)
            setName('')
            setContent('')
            toast.success( t('DETAILS.DISCUSSION.UPDATE_SUCCESS'),)
          }
        } catch (error) {
          setLoading(false)
          toast.error(
             t('DETAILS.DISCUSSION.SAVE_ERROR'),
          )
          console.log('error')
        }
      }
    }
  }
  const requiredFile = () => {
    if (
      (isSubmitted && initialContent === '<p><br></p>') ||
      (isSubmitted && !initialContent.trim())
    ) {
      return true
    }
  }

  const handleClose = () => {
    setSubmitted(false)
    setName('')
    setContent('')
    onClose()
  }
  return (
    <div>
      <Modal
        show={show}
        onHide={handleClose}
        dialogClassName="modal-right"
        className="info-modal info-modal--no-green"
      >
        <Modal.Header>
          <div
            className="btn-back"
            onClick={() => {
              handleClose()
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M12 19L5 12M5 12L12 5M5 12H19"
                stroke="#1D1D1B"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <span className="btn-back__text btn-back__text--green">Retour</span>
          </div>

          <Modal.Title>
            <h4 className="heading-4 heading-4--normal text--green">
              {!isEdit ? t('DETAILS.LABELS.NEW_DUSCUSSION')  : t('DETAILS.LABELS.MODIFY_DISCUSSION') }
            </h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="form-field">
              <label>{t('DETAILS.LABELS.FORM.TITLE') }</label>
              <textarea
                className={`form-control ${!name ? 'is-invalid' : ''}`}
                placeholder={t('DETAILS.LABELS.FORM.PLACEHOLDER_TITLE') }
                rows={3}
                value={name}
                onChange={(e) => setName(e.target.value)}
              />{' '}
              {isSubmitted && !name && (
                <div className="error-message"> {t('DETAILS.REQUIRED_FIELD') }</div>
              )}
            </div>

            <div className="form-field">
              <label>{t('DETAILS.LABELS.FORM.CONTENT') }</label>
              <CKEditorComponent
                initialContent={initialContent}
                onChange={handleEditorChange}
              />{' '}
              {requiredFile() && (
                <div className="error-message"> {t('DETAILS.REQUIRED_FIELD') }</div>
              )}
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          {!loading ? (
            <Button
              variant="primary"
              onClick={handleButtonClick}
              className="btn btn--primary w-100"
            >
              {!isEdit ? t('DETAILS.BUTTON.SHARE') : t('DETAILS.BUTTON.UPDATE')}
            </Button>
          ) : (
            <>
              <LoaderGameiro
                bg="loader-custom--transparent"
                type="loader-custom--relative"
              />
            </>
          )}
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default DiscussionCreateModale
