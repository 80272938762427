import TokenExpired from 'components/Modal/TokenExpired/token-expired'
import { Suspense, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Navigate, Outlet, useNavigate, useParams } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { selectUserInfo } from 'stores/slices/user-slice'
import { RootState } from 'stores/store'
import Header from './components/Header/Header'
import './styles/main.scss'
function App() {
  const userInfo = useSelector(selectUserInfo)
  const navigate = useNavigate()
  const isTokenExpired = useSelector(
    (state: RootState) => state.session.isTokenExpired,
  )

  const { i18n } = useTranslation()
  const { lang } = useParams()

  useEffect(() => {
    if (lang && ['fr', 'pt'].includes(lang)) {
      i18n.changeLanguage(lang)
    } else {
      !userInfo
        ? navigate('/fr')
        : navigate(`/${userInfo?.user.lang}/discussion`)
      console.log(`Langue non prise en charge: ${lang}`)
      i18n.changeLanguage('fr')
    }
  }, [i18n, lang, navigate, userInfo])

  return (
    <div className="App">
      <ToastContainer
        position="top-right"
        autoClose={3500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <TokenExpired isVisible={isTokenExpired} />
      <Suspense fallback="...is loading">
        <Header />
        {userInfo ? (
          <Navigate to={`${userInfo?.user.lang}/discussion`} replace />
        ) : (
          <Outlet />
        )}
      </Suspense>
    </div>
  )
}

export default App
