import LoaderGameiro from 'components/LoaderGameiro/Loader'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { fetchProducts, selectProducts } from 'stores/slices/product-slice'
import ProductItem from './ProductItem'
import './style.scss'

const ProductPage: React.FC = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { products, status } = useSelector(selectProducts)
  const { lang } = useParams()

  useEffect(() => {
    dispatch(fetchProducts(lang || 'fr') as any)
  }, [dispatch, lang])

  const showLoading = () => {
    return status === 'loading' && products.length === 0
  }

  return (
    <>
      <main className="main-container">
        <div className="container">
          <div className="product-page">
            <div className="row block--mb-45">
              <div className="col-12 col-sm-6">
                <div className="product-page__content">
                  <h1 className="title">{t('HEADER.Central')}</h1>
                  <p className="subtitle">{t('ACHAT.SUB_TITLE')}</p>
                </div>
              </div>
              <div className="col-12 col-sm-6">
                <div className="product-page__image">
                  <img src="/achat.png" alt="Header Imagery" />
                </div>
              </div>
            </div>
          </div>

          <div className="product-item__row">
            {showLoading() ? (
              <LoaderGameiro />
            ) : (
              <>
                {products &&
                  products?.length > 0 &&
                  products?.map((product) => (
                    <ProductItem
                      key={product.id}
                      product={product}
                      isFrom="purchas"
                    />
                  ))}
              </>
            )}
          </div>
        </div>
      </main>
    </>
  )
}

export default ProductPage
