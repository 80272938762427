/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
import LoaderGameiro from 'components/LoaderGameiro/Loader'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import authService from 'services/auth.service'
import AuthData from 'types/User'
import './style.scss'

export interface Coptation {
  id: number
  name: string
  email: string
  photo: string
  firstname: string
}

const ProfilViewMode: React.FC = () => {
  const { lang, username } = useParams()
  const [coptaUser, setCoptation] = useState<Coptation[]>([])
  const [loadingCopt, setLoadingCopt] = useState(false)
  const [loadingUser, setLoadingUser] = useState(false)
  const [childUser, setChildUser] = useState<any[]>([])
  const [userInfo, setInfoUser] = useState<AuthData>({} as any)
  const [loadingChild, setLoadingChild] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
    const checkChild = async () => {
      try {
        setLoadingChild(true)
        const response = await authService.child(username || '')
        if (response?.data) {
          setChildUser(response?.data)
          setLoadingChild(false)
        } else {
          setLoadingChild(false)
        }
      } catch (error) {
        setLoadingChild(false)
        console.error(
          "Erreur lors de la récupération des données de l'API 3",
          error,
        )
      }
    }

    const userInfoData = async () => {
      if (username) {
        setLoadingUser(true)
        try {
          const response = await authService.viewProfilInfo(username)

          if (response) {
            setLoadingUser(false)
            setInfoUser(response)
          }
        } catch (error) {
          setLoadingUser(false)
          console.error(
            "Erreur lors de la récupération des données de l'API 1",
            error,
          )
        }
      }
    }

    const checkCoptation = async () => {
      if (username) {
        try {
          setLoadingCopt(true)
          const response = await authService.getCoptation(username)
          if (response?.data) {
            setCoptation(response?.data)
            setLoadingCopt(false)
          } else {
            setLoadingCopt(false)
          }
        } catch (error) {
          setLoadingCopt(false)
          console.error(
            "Erreur lors de la récupération des données de l'API 2",
            error,
          )
        }
      }
    }
    checkChild()
    userInfoData()
    checkCoptation()
  }, [lang, username])

  const showLoading = () => {
    if (loadingCopt || loadingUser || loadingChild) {
      return true
    }
  }

  return (
    <>
      <div className="main-container main-container--w-bg">
        {showLoading() ? (
          <>
            <LoaderGameiro />
          </>
        ) : (
          <div className="profil-content">
            <div className="form-wrapper">
              <>
                <>
                  <div className="view-mode">
                    <div className="view-mode__profil">
                      <div className="view-mode__image">
                        <img
                          src={userInfo?.user?.photo}
                          height="80"
                          width="80"
                        />
                      </div>
                      <h1 className="form-title">
                        {userInfo?.user?.name} s{userInfo?.user?.firstname}{' '}
                      </h1>{' '}
                    </div>
                    {userInfo?.user?.role?.id === 3 && (
                      <span className="moderator-title">Modérateur</span>
                    )}
                    <div className="form-item">
                      <div className="form-item__left">
                        <label>Adresse email</label>
                      </div>
                      <div className="form-item__right">
                        <p className="form-item__desc">
                          {userInfo?.user?.email}
                        </p>
                      </div>
                    </div>
                    <div className="form-item">
                      <div className="form-item__left">
                        <label> Numéro de téléphone</label>
                      </div>
                      <div className="form-item__right">
                        <p className="form-item__desc">
                          {userInfo?.user?.phone_number}
                        </p>
                      </div>
                    </div>
                    <div className="form-item">
                      <div className="form-item__left">
                        <label>Pays</label>
                      </div>
                      <div className="form-item__right">
                        <p className="form-item__desc">
                          {userInfo?.user?.lang === 'fr'
                            ? 'France'
                            : 'Portugal'}
                        </p>
                      </div>
                    </div>
                    <div className="form-item">
                      <div className="form-item__left">
                        <label>Département</label>
                      </div>
                      <div className="form-item__right">
                        <p className="form-item__desc">
                          {userInfo?.user?.department?.name}
                        </p>
                      </div>
                    </div>
                    {userInfo?.user?.role?.id === 4 && (
                      <>
                        <div className="form-item">
                          <div className="form-item__left">
                            <label>Fichier SIRET</label>
                            <span>Extrait KBIS</span>
                          </div>
                          <div className="form-item__right">
                            <>
                              {userInfo?.user?.siret_file?.id ? (
                                <>
                                  {' '}
                                  <div className="file-upload file-upload--pdf">
                                    <span className="file-upload__icon--pdf"></span>
                                    <p className="file-upload__text file-upload__text--pdf">
                                      <span className="name">
                                        {userInfo?.user?.siret_file?.name ||
                                          userInfo?.user?.siret_file?.name}
                                      </span>
                                      <span className="size">
                                        .pdf | :{' '}
                                        {userInfo?.user?.siret_file?.size}
                                        MB
                                      </span>
                                    </p>
                                  </div>{' '}
                                </>
                              ) : (
                                <>
                                  {' '}
                                  <div className="form-item__right">
                                    <p className="form-item__desc">N/A</p>
                                  </div>{' '}
                                </>
                              )}
                            </>
                          </div>
                        </div>
                        <div className="form-item">
                          <div className="form-item__left">
                            <label>Fichier pièce d’identité</label>
                          </div>
                          <div className="form-item__right">
                            <>
                              {userInfo?.user?.IdFile?.id && (
                                <>
                                  {' '}
                                  <div className="file-upload file-upload--pdf">
                                    <span className="file-upload__icon--pdf"></span>
                                    <p className="file-upload__text file-upload__text--pdf">
                                      <span className="name">
                                        {userInfo?.user?.IdFile?.name}
                                      </span>
                                      <span className="size">
                                        .pdf | {userInfo?.user?.IdFile?.size}
                                        MB
                                      </span>
                                    </p>
                                  </div>{' '}
                                </>
                              )}
                            </>
                          </div>
                        </div>
                        <div className="form-item">
                          <div className="form-item__left">
                            <label>Fichier assurance RC</label>
                          </div>
                          <div className="form-item__right">
                            <>
                              {userInfo.user.insurance_file?.id && (
                                <>
                                  {' '}
                                  <div className="file-upload file-upload--pdf">
                                    <span className="file-upload__icon--pdf"></span>
                                    <p className="file-upload__text file-upload__text--pdf">
                                      <span className="name">
                                        {userInfo?.user.insurance_file?.name}
                                      </span>
                                      <span className="size">
                                        .pdf |{' '}
                                        {userInfo?.user.insurance_file?.size}
                                        MB
                                      </span>
                                    </p>
                                  </div>{' '}
                                </>
                              )}
                            </>
                          </div>
                        </div>{' '}
                        <div className="form-item form-item--no-border">
                          <div className="form-item__left">
                            <label>Parrainages</label>
                          </div>
                          {showLoading() ? (
                            <LoaderGameiro
                              bg="loader-custom--transparent"
                              type="loader-custom--relative"
                            />
                          ) : (
                            <>
                              <div className="form-item__right form-item__right--referral">
                                {coptaUser?.length === 0 && (
                                  <p className="form-item__desc">
                                    Aucun parrainage.
                                  </p>
                                )}
                                {coptaUser?.length > 0 && (
                                  <div className="referral">
                                    <p>
                                      Cet utilisateur a été parrainé par ...
                                    </p>
                                    <ul
                                      className={
                                        coptaUser.length === 2
                                          ? `list-referral with-parental${
                                              lang === 'pt' ? '--pt' : ''
                                            }`
                                          : 'list-referral'
                                      }
                                    >
                                      {coptaUser &&
                                        coptaUser?.map((item, index) => (
                                          <li
                                            key={index}
                                            className="list-referral__item"
                                            onClick={() => {
                                              navigate(
                                                `/${lang}/profil/${item?.id}`,
                                              )
                                            }}
                                          >
                                            <div className="referral__person">
                                              <div className="referral__image">
                                                <img
                                                  src={item?.photo}
                                                  alt=""
                                                  width="40"
                                                />
                                              </div>
                                              <p className="name">
                                                {item?.name} {item?.firstname}
                                              </p>
                                              <span className="referral__icon"></span>
                                            </div>
                                          </li>
                                        ))}
                                    </ul>
                                  </div>
                                )}

                                {childUser?.length > 0 && (
                                  <div className="referral">
                                    <p>Cet utilisateur a parrainé... </p>
                                    <div className="referral__wrapper">
                                      {childUser &&
                                        childUser?.map((item, index) => (
                                          <>
                                            {item.petit_enfant_id ? (
                                              <ul
                                                className={`d-flex align-items-center list-referral with-child${
                                                  lang === 'pt' ? '--pt' : ''
                                                }`}
                                              >
                                                <li
                                                  className="list-referral__item"
                                                  onClick={() => {
                                                    navigate(
                                                      `/${lang}/profil/${item?.enfant?.id}`,
                                                    )
                                                  }}
                                                >
                                                  <div className="referral__person">
                                                    <div className="referral__image">
                                                      <img
                                                        src={
                                                          item?.enfant?.photo
                                                        }
                                                        alt=""
                                                        width="40"
                                                      />
                                                    </div>
                                                    <p className="name">
                                                      {item.enfant?.name}{' '}
                                                      {item.enfant?.firstname}{' '}
                                                    </p>
                                                    <span className="referral__icon"></span>
                                                  </div>
                                                </li>
                                                <li
                                                  className="list-referral__item"
                                                  onClick={() => {
                                                    navigate(
                                                      `/${lang}/profil/${item?.petit_enfant_id?.id}`,
                                                    )
                                                  }}
                                                >
                                                  <div className="referral__person">
                                                    <div className="referral__image">
                                                      <img
                                                        src={
                                                          item?.petit_enfant_id
                                                            ?.photo
                                                        }
                                                        alt=""
                                                        width="40"
                                                      />
                                                    </div>
                                                    <p className="name">
                                                      {
                                                        item.petit_enfant_id
                                                          ?.name
                                                      }
                                                      {
                                                        item.petit_enfant_id
                                                          ?.firstname
                                                      }{' '}
                                                    </p>
                                                    <span className="referral__icon"></span>
                                                  </div>
                                                </li>
                                              </ul>
                                            ) : (
                                              <ul className="d-flex align-items-center list-referral no-child">
                                                <li
                                                  className="list-referral__item"
                                                  onClick={() => {
                                                    navigate(
                                                      `/${lang}/profil/${item?.enfant?.id}`,
                                                    )
                                                  }}
                                                >
                                                  <div className="referral__person">
                                                    <div className="referral__image">
                                                      <img
                                                        src={
                                                          item?.enfant?.photo
                                                        }
                                                        alt=""
                                                        width="40"
                                                      />
                                                    </div>
                                                    <p className="name">
                                                      {item.enfant?.name}
                                                      {
                                                        item.enfant?.firstname
                                                      }{' '}
                                                    </p>
                                                    <span className="referral__icon"></span>
                                                  </div>
                                                </li>
                                                <li className="list-referral__item">
                                                  <div className="referral__person">
                                                    <div className="referral__image">
                                                      <img
                                                        src={item?.photo}
                                                        alt=""
                                                        width="40"
                                                      />
                                                    </div>
                                                    <p className="name">
                                                      Child : PENA MEME
                                                    </p>
                                                    <span className="referral__icon"></span>
                                                  </div>
                                                </li>
                                              </ul>
                                            )}
                                          </>
                                        ))}
                                    </div>
                                  </div>
                                )}
                              </div>
                            </>
                          )}
                        </div>{' '}
                      </>
                    )}
                  </div>
                </>
              </>
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default ProfilViewMode
