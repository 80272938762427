// Header.tsx
import React from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useParams } from "react-router-dom";
import "./style.scss";

const Header: React.FC = () => {
  const { lang } = useParams() || "fr";
  const { t } = useTranslation();
  const location = useLocation();
  return (
    <header className="header">
      <nav className="navbar navbar-expand-lg">
        <div className="d-md-flex align-items-md-center justify-content-md-between">
          <Link to={`/${lang}`} className="navbar-brand">
            <img alt="logo" src="/logo.svg"></img>
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="burger-menu"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="list-connexion d-md-flex align-items-md-center">
              <li className="list-connexion__item">
                <Link
                  to={`/${lang}`}
                  className={`btn btn--normal ${
                    location.pathname.includes("login") ||
                    location.pathname === "/fr" ||
                    location.pathname === "/pt"
                      ? "btn--active"
                      : ""
                  } `}
                >
                  {t("HEADER.CONNEXION")}
                </Link>
              </li>
              <li className="list-connexion__item">
                <Link
                  to={`/${lang}/register`}
                  className={`btn btn--normal ${
                    location.pathname.includes("register") ? "btn--active" : ""
                  } `}
                >
                  {t("HEADER.INSCRIPTION")}
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;
