import setupAxiosInterceptor from 'interceptors/axiosInterceptor'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { RouterProvider } from 'react-router-dom'
import { store } from 'stores/store'
import reportWebVitals from './reportWebVitals'
import { router } from './routes/routes'
import './translations/i18n'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <>
    {setupAxiosInterceptor(store)}
    <Provider store={store}>
      <RouterProvider router={router} />
    </Provider>
  </>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
