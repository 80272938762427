/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/img-redundant-alt */
import LoaderGameiro from 'components/LoaderGameiro/Loader'
import { parsePhoneNumberFromString } from 'libphonenumber-js'
import 'pages/Login/style.scss'
import React, { useCallback, useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import authService from 'services/auth.service'
import departementService from 'services/departement.service'
import { selectUserInfo } from 'stores/slices/user-slice'
export interface FormState {
  name: string
  firstName: string
  email: string
  roleID?: number
  country: string
  countryName?: string
  deleteFile?: number
  disctrictName?: string
  password?: string
  confirmPassword?: string
  district: string
  phone: string
  PHOTO: File | null
  SIRET: File | null
  identityProof: File | null
  insuranceCertificate: File | null
  photoViewMode?: boolean
  siretViewMode?: boolean
  identityViewMode?: boolean
  insuranceViewMode?: boolean
}
export interface Department {
  id: number
  attributes: {
    name: string
    departementId: string
  }
}
export interface FormErrors {
  [key: string]: string
}

interface RegisterProps {
  isEdit?: boolean
  role?: number
}

const RegisterForm: React.FC<RegisterProps> = (props) => {
  const userInfo = useSelector(selectUserInfo)
  const { isEdit } = props
  const { lang, code } = useParams()
  const { t, i18n } = useTranslation()
  const [departments, setDepartments] = useState<Department[]>([])
  const [submitted, setSubmitted] = useState(false);
  const location = useLocation()
  const navigate = useNavigate()
  const roleID = location.pathname.includes('moderator') ? 3 : 4
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await departementService.list(
          userInfo?.user?.lang || lang,
        )

        setDepartments([...response?.data].sort(compareByName))
      } catch (error) {
        console.error('Erreur lors de la récupération des départements', error)
      }
    }

    fetchData()
  }, [])

  useEffect(() => {
    if (submitted) {
      validateForm();
    }
  }, [i18n.language]);

  const validateForm = () => {
    let newErrors: FormErrors = {}
    for (const [key, value] of Object.entries(form)) {
      newErrors[key] = validateField(key, value)
    }
    setErrors(newErrors)
  }  

  const initialFormState = {
    name: '',
    firstName: '',
    roleID: roleID,
    email: '',
    country:
      userInfo?.user?.lang.toLocaleUpperCase() ||
      lang?.toLocaleUpperCase() ||
      'FR',
    district: '',
    phone: '',
    password: '',
    confirmPassword: '',
    SIRET: null,
    PHOTO: null,
    identityProof: null,
    insuranceCertificate: null,
  }
  const [form, setForm] = useState<FormState>(initialFormState)

  const [showPassword, setShowPassword] = useState(false)
  const [showPassword2, setShowPassword2] = useState(false)

  const [errors, setErrors] = useState<FormErrors>({})

  const handleProfilePhotoDrop = useCallback(
    (acceptedFiles: File[]) => {
      setForm({ ...form, PHOTO: acceptedFiles[0] })
    },
    [form],
  ) as any

  const {
    getRootProps: getProfilePhotoRootProps,
    getInputProps: getProfilePhotoInputProps,
  } = useDropzone({
    onDrop: handleProfilePhotoDrop,
    accept: { 'image/*': ['.jpeg', '.png', '.jpg'] },
    multiple: false,
  })

  const formatPhoneNumber = (phoneNumber: string, country: any) => {
    const parsedPhoneNumber = parsePhoneNumberFromString(
      phoneNumber,
      country || 'FR',
    )
    return parsedPhoneNumber
      ? parsedPhoneNumber.formatInternational()
      : phoneNumber
  }

  const handleSiretDrop = useCallback(
    (acceptedFiles: File[]) => {
      setForm({ ...form, SIRET: acceptedFiles[0] })
    },
    [form],
  ) as any

  const {
    getRootProps: getSiretRootProps,
    getInputProps: getSiretInputProps,
  } = useDropzone({
    onDrop: handleSiretDrop,
    accept: { 'application/pdf': [] },
    multiple: false,
  })

  const handleIdentityDrop = useCallback(
    (acceptedFiles: File[]) => {
      setForm({ ...form, identityProof: acceptedFiles[0] })
    },
    [form],
  ) as any

  const {
    getRootProps: getIdentityRootProps,
    getInputProps: getIdentityInputProps,
  } = useDropzone({
    onDrop: handleIdentityDrop,
    accept: { 'application/pdf': [] },
    multiple: false,
  })

  const handleInsuranceDrop = useCallback(
    (acceptedFiles: File[]) => {
      setForm({ ...form, insuranceCertificate: acceptedFiles[0] })
    },
    [form],
  ) as any

  const {
    getRootProps: getInsuranceRootProps,
    getInputProps: getInsuranceInputProps,
  } = useDropzone({
    onDrop: handleInsuranceDrop,
    accept: { 'application/pdf': [] },
    multiple: false,
  })

  const togglePasswordVisibility = () => setShowPassword(!showPassword)
  const togglePasswordVisibility2 = () => setShowPassword2(!showPassword2)

  const compareByName = (a: Department, b: Department): number => {
    const nameA = a.attributes.name.toUpperCase()
    const nameB = b.attributes.name.toUpperCase()

    if (nameA < nameB) {
      return -1
    }
    if (nameA > nameB) {
      return 1
    }
    return 0
  }
  const validateField = (name: string, value: string | File | null): string => {
    switch (name) {
      case 'name':
        if (!value) return t('ERRORS.REGISTER.LASTNAME')
        break
      case 'firstName':
        if (!value) return t('ERRORS.REGISTER.FIRSTNAME')
        break
      case 'email':
        if (!value) return t('ERRORS.REGISTER.EMAIL')
        if (!/\S+@\S+\.\S+/.test(value.toString()))
          return t('ERRORS.REGISTER.INVALID_EMAIL')
        break
      case 'country':
        if (!value) return 'Le pays est requis.'
        break

      case 'PHOTO':
        if (!value) return t('ERRORS.REGISTER.PROFIL_PICTURE')
        break

      case 'identityProof':
        if (!value && roleID === 4) return t('ERRORS.REGISTER.LABELS.IDENTITY')
        break

      case 'insuranceCertificate':
        if (!value && roleID === 4) return t('ERRORS.REGISTER.LABELS.INSURANCE')
        break

      case 'password':
        if (!value) return t('ERRORS.REGISTER.PASSWORD')
        if (!/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}$/.test(value.toString()))
          return t('ERRORS.REGISTER.PASSWORD_MATCH')

        break

      case 'confirmPassword':
        if (!value) return t('ERRORS.REGISTER.PASSWORD_CONFIRM')
        if (value && value !== form.password)
          return t('ERRORS.REGISTER.PASSWORD_CONFIRM_NOT_MATCH')
        break

      case 'district':
        if (!value) return t('ERRORS.REGISTER.LABELS.DEPARTEMENT')
        break

      case 'phone':
        if (!value) return t('ERRORS.REGISTER.PHONE_NUMBER')
        if (!parsePhoneNumberFromString(value as any)?.isPossible())
          return t('ERRORS.REGISTER.PHONE_INVALIDE')
        break
    }
    return ''
  }

  const removeFirstSegment = (url: string) => {
    const segments = url.split('/')
    return `/${segments.slice(2).join('/')}`
  }

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    const { name, value } = event.target
    const newErrors = { ...errors, [name]: validateField(name, value) }
    setErrors(newErrors)
    setForm({ ...form, [name]: value })
  }

  const handleCountryInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    const { name, value } = event.target
    const newErrors = { ...errors, [name]: validateField(name, value) }
    setErrors(newErrors)
    setForm({ ...form, country: value, district: '' })
    setDepartments([])
    navigate(`/${value.toLowerCase()}${removeFirstSegment(location.pathname)}`)
    refetchDepartements(value.toLowerCase())
    i18n.changeLanguage(lang)
  }

  const handlePhoneInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    const { name, value } = event.target
    const newErrors = { ...errors, [name]: validateField(name, value) }
    setErrors(newErrors)
    setForm({ ...form, phone: formatPhoneNumber(value, form.country) })
  }

  const refetchDepartements = async (newLang: string) => {
    try {
      const response = await departementService.list(newLang)
      setDepartments([...response?.data].sort(compareByName))
    } catch (error) {
      console.error('Erreur lors de la récupération des départements', error)
    }
  }

  const handleFormSubmit = async (event: React.FormEvent) => {
    event.preventDefault()
    setSubmitted(true);
    // Validate all fields before submitting
    let newErrors: FormErrors = {}
    let isValid = true
    for (const [key, value] of Object.entries(form)) {
      const error = validateField(key, value)
      if (error) {
        isValid = false
        newErrors[key] = error
      }
    }

    setErrors(newErrors)
    if (!isValid) {
      console.error('Validation errors', newErrors)
      return
    }

    try {
      setLoading(true)
      const register = await authService.register(form, code || null)
      if (register.data) {
        toast.success(t('SUCCESS.REGISTER.REGISTER_SEND'))
        setForm(initialFormState)
        setLoading(false)
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        })
      }
    } catch (err) {
      setLoading(false)
      toast.error(t('ERRORS.REGISTER.REGISTER_FAILLED'))
    }

    console.log('Form is valid: submitting', form)
  }

  const convertKBtoMB = (kilobytes: number) => {
    return kilobytes / (1024 * 1024)
  }

  return (
    <>
      <div className="form-wrapper">
        <h1 className="form-title text-center">
          {isEdit ? 'Votre profils' : t('REGISTER.TITLE')}
        </h1>
        <form
          className="register-form form-w-padding"
          onSubmit={handleFormSubmit}
        >
          <div className={errors.name ? 'form-field error' : 'form-field'}>
            <label htmlFor="name">{t('REGISTER.LASTNAME')}</label>
            <input
              type="text"
              className="form-control"
              id="name"
              name="name"
              value={form.name}
              onChange={handleInputChange}
            />
            {errors.name && <div className="error-message">{errors.name}</div>}
          </div>
          {/* First Name input */}
          <div className={errors.firstName ? 'form-field error' : 'form-field'}>
            <label htmlFor="firstName">{t('REGISTER.FIRSTNAME')}</label>
            <input
              type="text"
              className="form-control"
              id="firstName"
              name="firstName"
              value={form.firstName}
              onChange={handleInputChange}
            />
            {errors.firstName && (
              <div className="error-message">{errors.firstName}</div>
            )}
          </div>
          {/* Email input */}
          <div className={errors.email ? 'form-field error' : 'form-field'}>
            <label htmlFor="email">{t('REGISTER.EMAIL')}</label>
            <input
              type="text"
              className="form-control"
              id="email"
              name="email"
              value={form.email}
              onChange={handleInputChange}
            />
            {errors.email && (
              <div className="error-message">{errors.email}</div>
            )}
          </div>

          <div className={errors.password ? 'form-field error' : 'form-field'}>
            <label htmlFor="password">{t('REGISTER.PASSWORD')}</label>
            <div className="password-input">
              <input
                type={showPassword ? 'text' : 'password'}
                id="password"
                name="password"
                className="form-control"
                value={form.password}
                onChange={handleInputChange}
              />
              <span
                className={`password-toggle-icon ${
                  showPassword ? 'password-visible' : ''
                }`}
                onClick={togglePasswordVisibility}
              ></span>
            </div>
            {errors.password && (
              <div className="error-message">{errors.password}</div>
            )}
          </div>

          <div
            className={
              errors.confirmPassword ? 'form-field error' : 'form-field'
            }
          >
            <label htmlFor="email">{t('REGISTER.PASSWORD_CONFIRM')}</label>
            <div className="password-input">
              <input
                type={showPassword2 ? 'text' : 'password'}
                id="confirmPassword"
                name="confirmPassword"
                className="form-control"
                value={form.confirmPassword}
                onChange={handleInputChange}
              />
              <span
                className={`password-toggle-icon ${
                  showPassword2 ? 'password-visible' : ''
                }`}
                onClick={togglePasswordVisibility2}
              ></span>
            </div>
            {errors.confirmPassword && (
              <div className="error-message">{errors.confirmPassword}</div>
            )}
          </div>

          <div className="form-group">
            <label htmlFor="PHOTO">{t('REGISTER.PROFIL_PICTURE')}</label>
            <div
              className={
                errors.PHOTO && !form.PHOTO ? 'dropzone error' : 'dropzone'
              }
              {...getProfilePhotoRootProps()}
            >
              <input {...getProfilePhotoInputProps()} />
              {!form.PHOTO && (
                <>
                  <div className="file-upload">
                    <span className="file-upload__icon" />
                    <p className="file-upload__text">
                      <span className="click">
                        {' '}
                        {t('REGISTER.LABELS.CLICK_IMPORT')}
                      </span>
                      {t('REGISTER.LABELS.DRAG_AND_DROP_IMAGE')}
                    </p>
                  </div>
                </>
              )}
            </div>
            {errors.PHOTO && !form.PHOTO && (
              <div className="error-message">{errors.PHOTO}</div>
            )}
            {form.PHOTO && (
              <>
                <div className="form-item__right">
                  <div className="profil__image">
                    <img
                      src={URL.createObjectURL(form.PHOTO)}
                      alt="Profile"
                      width="80"
                    />
                  </div>
                  <span
                    className="profil__close"
                    onClick={() => {
                      setForm({ ...form, PHOTO: null })
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M15 9L9 15M9 9L15 15M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
                        stroke="#98A2B3"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </span>
                </div>
              </>
            )}
          </div>

          {/* Country select */}
          <div className="form-group">
            <label htmlFor="country">{t('PROFILS.PAYS')}</label>
            <select
              className={`form-control ${errors.country ? 'is-invalid' : ''}`}
              id="country"
              name="country"
              value={form.country}
              onChange={handleCountryInputChange}
            >
              <option value="" disabled>
                {t('REGISTER.SELECT_PAYS')}
              </option>
              <option value="FR">France</option>
              <option value="PT">Portugal</option>
            </select>
            {errors.country && (
              <div className="error-message">{errors.country}</div>
            )}
          </div>
          {/* District input */}
          <div className={errors.district ? 'form-field error' : 'form-field'}>
            <label htmlFor="district">{t('REGISTER.DEPARTEMENT')}</label>
            <select
              className={`form-control ${errors.district ? 'is-invalid' : ''}`}
              id="district"
              name="district"
              value={form.district}
              onChange={handleInputChange}
            >
              <option value="" disabled>
                {t('REGISTER.SELECT_DEPARTEMENT')}
              </option>
              {departments.length > 0 &&
                departments.map((department) => (
                  <option key={department.id} value={department?.id}>
                    {department?.attributes?.name}
                  </option>
                ))}
            </select>

            {errors.district && (
              <div className="error-message">
                {t('ERRORS.REGISTER.LABELS.DEPARTEMENT')}
              </div>
            )}
          </div>
          {/* Phone input */}
          <div className={errors.phone ? 'form-field error' : 'form-field'}>
            <label htmlFor="phone">{t('PROFILS.PHONE_NUMBER')}</label>
            <input
              type="text"
              className="form-control"
              id="phone"
              name="phone"
              value={form.phone}
              onChange={handlePhoneInputChange}
            />
            {errors.phone && (
              <div className="error-message">{errors.phone}</div>
            )}
          </div>
          {/* SIRET File input */}
          {roleID === 4 && (
            <>
              <div className="form-group">
                <label htmlFor="SIRET"> {t('REGISTER.LABELS.SIRET')}</label>

                <div
                  className={`dropzone form-field ${
                    form.SIRET ? 'drag-active' : ''
                  }`}
                  {...getSiretRootProps()}
                >
                  <input {...getSiretInputProps()} />
                  {!form.SIRET && (
                    <>
                      <div className="file-upload">
                        <span className="file-upload__icon"></span>
                        <p className="file-upload__text">
                          <span className="click">
                            {' '}
                            {t('REGISTER.LABELS.CLICK_IMPORT')}
                          </span>
                          {t('REGISTER.LABELS.DRAG_AND_DROP_PDF')}
                        </p>
                      </div>
                    </>
                  )}
                </div>
                {form.SIRET && (
                  <>
                    <div className="file-upload file-upload--pdf">
                      <span className="file-upload__icon--pdf"></span>
                      <p className="file-upload__text file-upload__text--pdf">
                        <span className="name">{form.SIRET.name}</span>
                        <span className="size">
                          .pdf | {convertKBtoMB(form.SIRET.size).toFixed(2)} MB
                        </span>
                      </p>
                      <span
                        className="file-upload__close"
                        onClick={() => {
                          setForm({ ...form, SIRET: null })
                        }}
                      ></span>
                    </div>
                  </>
                )}
              </div>

              {/* Identity Proof File input */}
              <div className="form-group">
                <label htmlFor="IDENTITY">
                  {' '}
                  {t('REGISTER.LABELS.IDENTITY')}
                </label>

                <div
                  className={
                    errors.identityProof && !form.identityProof
                      ? 'dropzone form-field error'
                      : `dropzone form-field ${
                          form.identityProof ? 'drag-active' : ''
                        }`
                  }
                  {...getIdentityRootProps()}
                >
                  <input {...getIdentityInputProps()} />
                  {!form.identityProof && (
                    <>
                      <div className="file-upload">
                        <span className="file-upload__icon"></span>
                        <p className="file-upload__text">
                          <span className="click">
                            {' '}
                            {t('REGISTER.LABELS.CLICK_IMPORT')}
                          </span>
                          {t('REGISTER.LABELS.DRAG_AND_DROP_PDF')}
                        </p>{' '}
                      </div>
                    </>
                  )}
                </div>

                {form.identityProof && (
                  <>
                    <div className="file-upload file-upload--pdf">
                      <span className="file-upload__icon--pdf"></span>
                      <p className="file-upload__text file-upload__text--pdf">
                        <span className="name">{form.identityProof.name}</span>
                        <span className="size">
                          .pdf |{' '}
                          {convertKBtoMB(form.identityProof.size).toFixed(2)}{' '}
                          {''}
                          MB
                        </span>
                      </p>
                      <span
                        className="file-upload__close"
                        onClick={() => {
                          setForm({ ...form, identityProof: null })
                        }}
                      ></span>
                    </div>
                  </>
                )}
                {errors.identityProof && !form.identityProof && (
                  <div className="error-message">{errors.identityProof}</div>
                )}
              </div>

              {/* Insurance Certificate File input */}
              <div className="form-group">
                <label htmlFor="INSURENCE">
                  {' '}
                  {t('REGISTER.LABELS.INSURANCE')}
                </label>
                <div
                  className={
                    errors.insuranceCertificate && !form.insuranceCertificate
                      ? 'dropzone form-field error'
                      : `dropzone form-field  ${
                          form.insuranceCertificate ? 'drag-active' : ''
                        }`
                  }
                  {...getInsuranceRootProps()}
                >
                  <input {...getInsuranceInputProps()} />
                  {!form.insuranceCertificate && (
                    <>
                      <div className="file-upload">
                        <span className="file-upload__icon"></span>
                        <p className="file-upload__text">
                          <span className="click">
                            {' '}
                            {t('REGISTER.LABELS.CLICK_IMPORT')}
                          </span>
                          {t('REGISTER.LABELS.DRAG_AND_DROP_PDF')}
                        </p>
                      </div>
                    </>
                  )}
                </div>
                {form.insuranceCertificate && (
                  <>
                    <div className="file-upload file-upload--pdf">
                      <span className="file-upload__icon--pdf"></span>
                      <p className="file-upload__text file-upload__text--pdf">
                        <span className="name">
                          {form.insuranceCertificate.name}
                        </span>
                        <span className="size">
                          {' '}
                          .pdf |{' '}
                          {convertKBtoMB(
                            form.insuranceCertificate.size,
                          ).toFixed(2)}{' '}
                          {''}
                          MB
                        </span>
                      </p>
                      <span
                        className="file-upload__close"
                        onClick={() => {
                          setForm({ ...form, insuranceCertificate: null })
                        }}
                      ></span>
                    </div>
                  </>
                )}
                {errors.insuranceCertificate && !form.insuranceCertificate && (
                  <div className="error-message">
                    {errors.insuranceCertificate}
                  </div>
                )}
              </div>
            </>
          )}
          {/* Submit button */}
          {!loading ? (
            <button type="submit" className="btn btn--primary w-100">
              {t('REGISTER.REGISTER_BUTTON')}
            </button>
          ) : (
            <LoaderGameiro
              bg="loader-custom--transparent"
              type="loader-custom--relative"
            />
          )}
        </form>
      </div>
    </>
  )
}

export default RegisterForm
